import {connect} from 'react-redux';
import LeaveEdit from '../../views/leaves/leave_edit';
import {getLeave, editLeave} from '../../actions/leaves_actions';

function mapStateToProps(state) {
  return {
    isUpdated: state.leaves.isUpdated,
    leave: state.leaves.leave,
    id: state.leaves.id,
    errors: state.leaves.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLeave(id) {
      dispatch(getLeave(id));
    },
    editLeave(params) {
      dispatch(editLeave(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveEdit);
