import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ErrorMessage from "../../components/ErrorMessage";
import Icon from "../../components/Icon";

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../assets/css/date-picker.scss';

export default class StaffEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFirstWorkingDateChange = this.handleFirstWorkingDateChange.bind(this);
    this.handleClearedProbationDateChange = this.handleClearedProbationDateChange.bind(this);
  }

  componentWillMount() {
    this.staffId = this.props.match.params.id;
    this.reloadData();
    this.goBack = this.goBack.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  reloadData() {
    this.props.getStaff(this.staffId);
  }

  componentWillReceiveProps(newProps) {
    this.staff = newProps.staff;
    if (newProps.isUpdated == true) {
      this.goBack();
    }

    if (this.staff) {
      this.state = {
        firstWorkingDate: moment(this.staff.first_working_date),
        clearedProbationDate: moment(this.staff.cleared_probation_date)
      };
    }
  }

  handleFirstWorkingDateChange(date) {
    this.setState({
      firstWorkingDate: date
    });
    this.staff.first_working_date = date.format("DD/MM/YYYY");
  }

  handleClearedProbationDateChange(date) {
    this.setState({
      clearedProbationDate: date
    });
    this.staff.cleared_probation_date = date.format("DD/MM/YYYY");
  }

  submitUpdate() {
    this.props.editStaff(this.staff);
  }

  errorMessage(errors) {
    if (errors) {
      return (
        <ErrorMessage message={errors.message}/>
      );
    }
  }

  renderInformation() {
    if (this.staff) {
      return (
        <form className="form">

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Username</label>
                <input className="form-control"
                       defaultValue={this.staff.username} readOnly />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label>Full Name</label>
                <input className="form-control"
                       defaultValue={this.staff.full_name}
                       onChange={e => this.staff.full_name = e.target.value}/>
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label>Email</label>
                <input className="form-control"
                       defaultValue={this.staff.email}
                       onChange={e => this.staff.email = e.target.value}/>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col">
            </div>

            <div className="col">
            </div>

            <div className="col">
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Role</label>
                <select className="form-control"
                        defaultValue={this.staff.role}
                        onChange={e => this.staff.role = e.target.value}>
                  <option value="superadmin">Superadmin</option>
                  <option value="admin">Admin</option>
                  <option value="staff">Staff</option>
                </select>
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label>First working date</label>
                <DatePicker className="form-control"
                            showMonthDropdown
                            showYearDropdown
                            selected={this.state.firstWorkingDate._isValid ? this.state.firstWorkingDate : ''}
                            dateFormat="DD/MMM/YYYY"
                            placeholderText="No data"
                  onChange={this.handleFirstWorkingDateChange}
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label>Cleared probation date</label>
                <DatePicker className="form-control"
                            showMonthDropdown
                            showYearDropdown
                            selected={this.state.clearedProbationDate._isValid ? this.state.clearedProbationDate : ''}
                            dateFormat="DD/MMM/YYYY"
                            placeholderText="No data"
                  onChange={this.handleClearedProbationDateChange}
                />
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <label>Available leave days</label>
            <input className="form-control"
                   type="number"
                   defaultValue={this.staff.available_leaves}
                   onChange={e => this.staff.available_leave_days = e.target.value}/>
          </div>

          <div className="form-group">
            <label>Bonus days from last year</label>
            <input className="form-control"
                   type="number"
                   defaultValue={this.staff.bonus_days}
                   onChange={e => this.staff.bonus_days = e.target.value}/>
          </div>

          <div className="form-group">
            <label>Is active?</label>
            <select className="form-control"
                    defaultValue={this.staff.is_active}
                    onChange={e => this.staff.is_active = e.target.value}>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>

          <button className="btn btn-primary" onClick={this.submitUpdate}>Submit</button>

        </form>
      );
    }
    return (<p>No staff information</p>);
  }

  render() {
    return (
      <div className="card">
        {this.errorMessage(this.props.errors)}

        <div className="card-header">
          <h5 className="card-title">
            <Link to="" className="btn btn-light" onClick={this.goBack}><Icon icon="arrow-left" /></Link>
            Edit Staff
          </h5>
        </div>

        <div className="card-body">
          {this.renderInformation()}
        </div>

      </div>
    );
  }
}
