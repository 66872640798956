import {ACTION_STATES, actionWithState, asyncAction} from './core_action';
import ApiClient from '../util/api_client';

export const PROJECTS = 'PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const NEW_PROJECT = 'NEW_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const GET_CLIENT_CHANNEL_NAMES = 'GET_CLIENT_CHANNEL_NAMES';
export const GET_MAN_DAY_CREDIT_HISTORIES = 'GET_MAN_DAY_CREDIT_HISTORIES';
export const TOP_UP_MAN_DAY_CREDITS = 'TOP_UP_MAN_DAY_CREDITS';

export function projects(params) {
  return asyncAction(PROJECTS, ApiClient.projects(params));
}

export function getProject(id) {
  return asyncAction(GET_PROJECT, ApiClient.getProject(id));
}

export function editProject(project) {
  return asyncAction(EDIT_PROJECT, ApiClient.editProject(project));
}

export function newProject(project) {
  return asyncAction(NEW_PROJECT, ApiClient.newProject(project));
}

export function deleteProject(project) {
  return asyncAction(DELETE_PROJECT, ApiClient.deleteProject(project));
}

export function getClientChannelNames() {
  return asyncAction(GET_CLIENT_CHANNEL_NAMES, ApiClient.getClientChannelNames());
}

export function getManDayCreditHistories(project_id, params) {
  return asyncAction(GET_MAN_DAY_CREDIT_HISTORIES, ApiClient.getManDayCreditHistories(project_id, params));
}

export function topUpManDayCredits(project_id, params) {
  return asyncAction(TOP_UP_MAN_DAY_CREDITS, ApiClient.topUpManDayCredits(project_id, params));
}
