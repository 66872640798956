import {connect} from 'react-redux';
import WikisStaffIndex from '../../views/wikis/wikis_staff_index';
import {getStaffPosts} from '../../actions/wikis_actions';

function mapStateToProps(state) {
  return {
    categories: state.wikis.categories
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getStaffPosts(params) {
      dispatch(getStaffPosts(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WikisStaffIndex);
