import {ACTION_STATES} from '../actions/core_action';

export default function handleActionState(state = {}, action) {
  switch (action.state) {
    case ACTION_STATES.BEGIN:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        data: null,
        object: null,
        error: null
      });
    case ACTION_STATES.FINISH:
      return Object.assign({}, state, {
        isLoading: false,
        isError: false,
        data: action.data,
        object: action.object,
        error: null
      });
    case ACTION_STATES.ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        data: null,
        object: null,
        error: action.data
      });
    default:
      return state;
  }
}
