import {TIMESHEETS, NEW_TIMESHEET, EDIT_TIMESHEET, DELETE_TIMESHEET} from '../actions/timesheets_actions';
import {ACTION_STATES} from '../actions/core_action';
import handleActionState from './core_reducer';

export default function timesheets(state = {}, action) {
  state = handleActionState(state, action);
  switch (action.type) {
  case TIMESHEETS:
    return Object.assign({}, state, {
      timesheets: state.object ? state.object.timesheets : undefined,
      currentPage: state.object ? state.object.current_page : undefined,
      totalPage: state.object ? state.object.total_page : undefined
    });
  case NEW_TIMESHEET:
  case EDIT_TIMESHEET:
  case DELETE_TIMESHEET:
    default:
      return state;
  }
}
