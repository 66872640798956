import {connect} from 'react-redux';
import CategoryEdit from '../../views/wikis/category_edit';
import {getCategory, editCategory} from '../../actions/wikis_actions';

function mapStateToProps(state) {
  return {
    category: state.wikis.category,
    errors: state.wikis.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCategory(id) {
      dispatch(getCategory(id));
    },
    editCategory(category) {
      dispatch(editCategory(category));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEdit);
