import {ACTION_STATES, actionWithState, asyncAction} from './core_action';
import ApiClient from '../util/api_client';

export const TIMESHEETS = 'TIMESHEETS';
export const EDIT_TIMESHEET = 'EDIT_TIMESHEET';
export const NEW_TIMESHEET = 'NEW_TIMESHEET';
export const DELETE_TIMESHEET = 'DELETE_TIMESHEET';

export function timesheets(params) {
  return asyncAction(TIMESHEETS, ApiClient.timesheets(params));
}

export function editTimesheet(timesheet) {
  return asyncAction(EDIT_TIMESHEET, ApiClient.editTimesheet(timesheet));
}

export function newTimesheet(timesheet) {
  return asyncAction(NEW_TIMESHEET, ApiClient.newTimesheet(timesheet));
}

export function deleteTimesheet(timesheet) {
  return asyncAction(DELETE_TIMESHEET, ApiClient.deleteTimesheet(timesheet));
}
