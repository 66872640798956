import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class SignUp extends Component {

  constructor(props) {
    super(props);
    this.username = "";
    this.password = "";
    this.password_confirmation = "";
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    var target = event.target;
    this[target.name] = target.value;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.signUp({username: this.username, password: this.password, password_confirmation: this.password_confirmation});
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isLoggedIn) {
      this.props.history.push('/');
    }
  }

  renderError(error) {
    if (error) {
      return (
        <div className="alert alert-dismissible alert-warning">
          <h4>Error</h4>
          <p>{error.message}</p>
        </div>
      );
    }
    return <div/>;
  }

  render() {
    return (
      <div className="row full-height">
        <div className="col-lg-3 left-panel">
          <h4 className="login-header">RTH Internal System</h4>
          <div className="login-form">
            <h3>Sign Up</h3>

            {this.renderError(this.props.error)}

            <form className="form" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input name="username" type="text" className="form-control" placeholder="Username"
                onChange={this.handleChange}/>
              </div>

              <div className="form-group">
                <input name="password" type="password" className="form-control"
                  placeholder="Password" onChange={this.handleChange}/>
              </div>

              <div className="form-group">
                <input name="password_confirmation" type="password" className="form-control"
                  placeholder="Password Confirmation" onChange={this.handleChange}/>
              </div>

              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={this.props.isLoading}>
                  Sign Up
                </button>
              </div>
              <div className="form-group">
                <Link to="/login">Already have an account? Login.</Link>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-9 right-panel">
        </div>
      </div>
    );
  }
}
