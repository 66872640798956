import React, {Component} from 'react';

export default class Icon extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <svg
          width={this.props.size || 18}
          height={this.props.size || 18}
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...this.props}
          >
        <use xlinkHref={ "/img/feather-sprite.svg#" + this.props.icon }/>
      </svg>
    );
  }

}
