import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import ErrorMessage from "../../components/ErrorMessage";

export default class CategoryNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.category = {};
    this.submitted = false;
  }

  componentWillMount() {
    this.goBack = this.goBack.bind(this);
    this.submit = this.submit.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentWillReceiveProps(newProps) {
    if (this.submitted && this.category) {
      this.goBack();
    }
  }

  submit() {
    this.submitted = true;
    this.props.newCategory(this.category);
  }

  errorMessage(errors) {
    if (errors) {
      return (
        <ErrorMessage message={errors.message}/>
      );
    }
  }

  renderFormData() {
    return (
      <form className="form">

        <div className="form-group">
          <label>Title</label>
          <input className="form-control" onChange={e => this.category.title = e.target.value}/>
        </div>

        <div className="form-group">
          <label>Description</label>
          <input className="form-control" onChange={e => this.category.description = e.target.value}/>
        </div>

        <button className="btn btn-primary" type="button" onClick={this.submit}>Submit</button>

      </form>
    );
  }

  render() {
    return (
      <div className="col-sm-12">
        {this.errorMessage(this.props.errors)}

        <div className="content-title">
          <h3>New Category</h3>
          <div className="actions">
            <Link to="" onClick={this.goBack}>Back</Link>
          </div>
        </div>

        {this.renderFormData()}
      </div>
    );
  }
}
