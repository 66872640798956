import React, {Component} from 'react';
import Icon from '../components/Icon';

export default class TopNav extends Component {

  constructor(props) {
    super(props);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleSignOut(e) {
    e.preventDefault();
    this.props.signOutConfirm();
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg">
        <div className="nav-actions">
          <a href="" className="btn btn-nav" onClick={this.handleSignOut}>
            <Icon icon="power" />
          </a>
        </div>
      </nav>
    );
  }
}
