import React, {Component} from 'react';
import ErrorMessage from "../../components/ErrorMessage";
import Icon from "../../components/Icon";
import {Link} from 'react-router-dom';
import Pagination from '../shared/pagination';
import AddManDayCreditsDialog from "./add_man_day_credits_dialog";

export default class ProjectEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1
    };
  }

  componentWillMount() {
    this.projectId = this.props.match.params.id;
    this.reloadData();
    this.goBack = this.goBack.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.showAddManDayCreditsDialog = this.showAddManDayCreditsDialog.bind(this);
    this.topUpDayCredits = this.topUpDayCredits.bind(this);
  }

  onChangePage(page) {
    this.props.getManDayCreditHistories(this.projectId, {page: page});
  }

  goBack() {
    this.props.history.goBack();
  }

  submitUpdate() {
    this.props.editProject(this.project);
  }

  reloadData() {
    this.props.getProject(this.projectId);
    this.props.getClientChannelNames();
    this.props.getManDayCreditHistories(this.projectId);
  }

  componentWillReceiveProps(newProps) {
    this.project = newProps.project;
    this.clientChannels = newProps.clientChannels;
    this.credit_histories = newProps.manDayCreditHistories;

    if (newProps.isUpdated == true) {
      this.goBack();
    }
    if (this.project) {
      if (this.project.client_channel) {
        this.setState({
          value: this.project.client_channel
        });
      }
    }
  }

  errorMessage(errors) {
    if (errors) {
      return (
        <ErrorMessage message={errors.message}/>
      );
    }
  }

  onChangeSlackSelect(e) {
    this.project.client_channel = e.target.value;
    this.setState({
      value: this.project.client_channel
    });
  }

  renderChannelSelect() {
    if (!this.props.clientChannels) {
      return null;
    }
    return (
      <select className="form-control"
              value={this.state.value}
              onChange={e => this.onChangeSlackSelect(e)}>
        <option value='1' disabled>Not set</option>
        {
          this.props.clientChannels.group_names.map(group =>
            <option>{group.name}</option>)
        }
      </select>
    );
  }

  renderMinusPlusSign(kind) {
    if (kind === 'work_log' || kind === 'expired') {
      return '-'
    } else {
      return '+'
    }
  }

  renderCreditInfo(cre_his) {
    if (cre_his.credit !== undefined) {
      var color = cre_his.credit.expired === true ? 'red' : 'green';
      return (
        <td style={{color: color}}>{cre_his.credit.id + " (" + cre_his.credit.used + ")"}</td>
      );
    } else {
      return (
        <td/>
      );
    }
  }

  renderActionDate(cre_his) {
    if (cre_his.kind == 'work_log') {
      return cre_his.start_date;
    } else {
      return cre_his.created_at;
    }
  }

  renderStartDate(cre_his) {
    if (cre_his.kind != 'work_log') {
       return cre_his.start_date;
    }
  }

  creditBody() {
    if (this.props.manDayCreditHistories === undefined || this.props.manDayCreditHistories.credit_histories.length === 0) {
      return (
        <tr>
          <td colSpan="8">No Data</td>
        </tr>
      );
    }

    return (
      this.props.manDayCreditHistories.credit_histories.map((cre_his) =>
        <tr>
          <td>{cre_his.id}</td>
          <td>{this.renderActionDate(cre_his)}</td>
          <td>{cre_his.action}</td>
          <td>{this.renderStartDate(cre_his)}</td>
          <td>{cre_his.expiry_date}</td>
          <td>{this.renderMinusPlusSign(cre_his.kind)} {cre_his.credit_change}</td>
          <td>{cre_his.credit_balance}</td>
          {this.renderCreditInfo(cre_his)}
        </tr>
      )
    );
  }

  topUpDayCredits(params) {
    // DateTimePicker: if start_date and expiry_date picker were selected, they became string
    //                 not Date obj, so have to convert to DD/MM/YYYY
    if (typeof (params.start_date) !== 'string') {
      params.start_date = new Intl.DateTimeFormat('en-AU').format(params.start_date);
    }
    // null for Never expiry
    if (typeof (params.expiry_date) !== 'string' && params.expiry_date !== null) {
      params.expiry_date = new Intl.DateTimeFormat('en-AU').format(params.expiry_date);
    }
    this.props.topUpManDayCredits(this.projectId, params);
    this.reloadData();
  };

  showAddManDayCreditsDialog(e) {
    e.preventDefault();
    this.dialog.show();
  }

  renderManDayCreditHistory() {
    return (
      <div>
        <div className="card-body">
          <h4>Man-day credits</h4>
        </div>

        <table className="table table-hover mt-2">
          <thead>
          <tr>
            <th>ID</th>
            <th>Action Date</th>
            <th>Action</th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Credit change</th>
            <th>Credit Balance</th>
            <th>Credit ID (Used)</th>
          </tr>
          </thead>
          <tbody>
            {this.creditBody()}
          </tbody>
        </table>

        <Pagination totalPages={this.props.totalPages} onChangePage={this.onChangePage.bind(this)}/>
      </div>
    )
  }

  renderInformation() {
    if (this.project) {
      return (
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">
              <Link to="" className="btn btn-light" onClick={this.goBack}><Icon icon="arrow-left" /></Link>
              Edit Project
            </h5>
          </div>
          <div className="card-body">
            <form className="form">
              <div className="form-group">
                <label>Name</label>
                <input className="form-control" defaultValue={this.project.name}
                       required="required"
                       onChange={e => this.project.name = e.target.value}/>
              </div>

              <div className="form-group">
                <label>Code</label>
                <input className="form-control" defaultValue={this.project.code}
                       required="required"
                       onChange={e => this.project.code = e.target.value}/>
              </div>

              <div className="form-group">
                <label>Slack client channel</label>
                {this.renderChannelSelect()}
              </div>

              <button className="btn btn-primary" onClick={this.submitUpdate}>Update</button>
              <button className="btn btn-info" onClick={this.showAddManDayCreditsDialog}>Add Man-Day Credits</button>

              <br/>
              <br/>
              (1) Invite the RTH Bot to the desired client channels<br/>
              (2) Select the client slack channel above<br/>
              (3) Each time devs save their reports,
              the summary will be posted on #morning-standup,
              and individual reports will go to corresponding client channel

              <hr/>

            </form>
          </div>

          {this.renderManDayCreditHistory()}
        </div>
      );
    }
    return (<p>No project information</p>);
  }

  render() {
    return (
      <div>
        {this.errorMessage(this.props.errors)}

        {this.renderInformation()}

        <AddManDayCreditsDialog topUpManDayCredits={this.topUpDayCredits} ref={el => this.dialog = el} projectId={this.projectId}/>
      </div>
    );
  }
}
