import React, {Component} from 'react';
import Icon from '../../components/Icon';

export default class SignOutDialog extends Component {

  componentWillMount() {
    this.state = { show: false };
    this.logout = this.props.logout.bind(this);
    this.hide = this.hide.bind(this);
  }

  show() {
    this.setState({ show: true });
  }

  hide(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ show: false });
  }

  componentWillUnmount() {
    this.hide()
  }

  render() {
    return (
      <div class={"popup" + (this.state.show ? ' popup-show' : '')}>
        <div class="popup-overlay"></div>

        <div class="popup-wrapper">
          <div class="popup-content">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Sign out</h5>

                <div>
                  <a href="" class="btn btn-close" onClick={this.hide}>
                    <Icon icon="x" />
                  </a>
                </div>
              </div>

              <div className="card-body">
                <p>Are you sure you want to sign out?</p>
              </div>

              <div className="card-footer">
                <button type="button" className="btn btn-secondary" onClick={this.hide}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={this.logout}>Yes</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
