import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Pagination from '../shared/pagination';
import AuthUtil from '../../util/auth_util';
import ConfirmDeleteDialog from '../shared/confirm_delete_dialog';

export default class WikisIndex extends Component {

  componentWillMount() {
    this.delete = this.delete.bind(this);
    this.reloadData();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.id != undefined && this.deleting) {
      this.reloadData();
      this.deleting = false;
    }
  }

  reloadData() {
    this.props.getPosts({page: 1});
  }

  confirmDelete(id) {
    this.selectedPostId = id;
    this.dialog.show();
  }

  delete() {
    this.dialog.hide();
    if (this.selectedCategoryId < 0) {
      return;
    }
    this.deleting = true;
    this.props.deletePost(this.selectedPostId);
  }

  // Pagination
  onChangePage(page) {
    this.props.getPosts({page: page});
  }

  newWikiButton() {
    if (AuthUtil.isSuperadmin() || AuthUtil.isAdmin()) {
      return (
        <Link to={"/admin/wikis/new"}>Create new post</Link>
      );
    }
  }

  body() {
    if (this.props.posts == undefined || this.props.posts.length == 0) {
      return (
        <tr>
          <td colSpan="6">No Data</td>
        </tr>
      );
    }

    return (
      this.props.posts.map((post, index) =>
        <tr>
          <td>{index + 1}</td>
          <td>{post.id}</td>
          <td><Link to={'/admin/wikis/' + post.id}>{post.title}</Link></td>
          <td>{post.category}</td>
          <td>{post.description}</td>
          <td>
            <Link to={'/admin/wikis/' + post.id + '/edit'}>Edit</Link>
            <a onClick={ () => this.confirmDelete(post.id) }>Delete</a>
          </td>
        </tr>
      )
    );
  }

  render() {

    return (
      <div className="col-sm-12">

        <div className="content-title">
          <h3>Wiki</h3>
          <div className="actions">
            {this.newWikiButton()}
          </div>
        </div>

        <table className="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Title</th>
              <th>Category</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.body()}
          </tbody>
        </table>

        <Pagination totalPages={this.props.totalPages} onChangePage={this.onChangePage.bind(this)}/>

        <ConfirmDeleteDialog ref={ el => this.dialog = el } action={this.delete} item="post" />

      </div>
    );
  }
}
