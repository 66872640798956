import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import ErrorMessage from "../../components/ErrorMessage";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../assets/css/date-picker.scss';

export default class LeaveNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFromDayChange = this.handleFromDayChange.bind(this);
    this.handleToDayChange = this.handleToDayChange.bind(this);
  }

  handleFromDayChange(date) {
    this.setState({
      startDate: date
    });
    this.leave.from_date = date.format("DD/MM/YYYY");
  }

  handleToDayChange(date) {
    this.setState({
      endDate: date
    });
    this.leave.to_date = date.format("DD/MM/YYYY");
  }

  componentWillMount() {
    this.reloadData();
    this.goBack = this.goBack.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  reloadData() {
    this.props.getStaffList();
  }

  componentWillReceiveProps(newProps) {
    this.leave = {};
    this.staffs = newProps.staffs;
    if (newProps.isUpdated == true) {
      this.goBack();
    }
  }

  submitUpdate() {
    this.leave.user_id = ReactDOM.findDOMNode(this.refs.userSelect).value;
    this.props.newLeave(this.leave);
  }

  errorMessage(errors) {
    if (errors) {
      return (
        <ErrorMessage message={errors.message}/>
      );
    }
  }

  renderOptions(staffs) {
    if (staffs) {
      return (
        staffs.map(staff =>
          <option key={staff.id} value={staff.id}>{staff.username}</option>)
      );
    }
    return (<option value=''>No staff</option>);
  }

  renderInformation() {
    // console.log('render ' + JSON.stringify(this.props.staffs));
    return (
      <form className="form">

        <div className="form-group">
          <label>User</label>
          <select className="form-control" ref="userSelect"
                  onChange={e => this.leave.user_id = e.target.value}>
            {this.renderOptions(this.props.staffs)}
          </select>
        </div>

        <div className="form-group">
          <label>From date:</label>
          <DatePicker className="form-control"
                      selected={this.state.startDate}
                      placeholderText="Click to select a date"
                      dateFormat="DD/MM/YYYY"
                      onChange={this.handleFromDayChange}/>
        </div>

        <div className="form-group">
          <label>To date:</label>
          <DatePicker className="form-control"
                      selected={this.state.endDate}
                      placeholderText="Click to select a date"
                      dateFormat="DD/MM/YYYY"
                      onChange={this.handleToDayChange}/>
        </div>

        <div className="form-group">
          <label>Reason</label>
          <input className="form-control" onChange={e => this.leave.reason = e.target.value}/>
        </div>

        <div className="form-group">
          <label>Hours</label>
          <input className="form-control" onChange={e => this.leave.hours = e.target.value}/>
        </div>

        <button className="btn btn-primary" onClick={this.submitUpdate}>Submit</button>

      </form>
    );
  }

  render() {
    return (
      <div className="col-sm-12">
        {this.errorMessage(this.props.errors)}

        <div className="content-title">
          <h3>New Leave</h3>
          <div className="actions">
            <Link to="" onClick={this.goBack}>Back</Link>
          </div>
        </div>

        {this.renderInformation()}
      </div>
    );
  }
}
