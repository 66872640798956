import {ACTION_STATES, actionWithState, asyncAction} from './core_action';
import ApiClient from '../util/api_client';

export const LEAVES = 'LEAVES';
export const GET_LEAVE = 'GET_LEAVE';
export const EDIT_LEAVE = 'EDIT_LEAVE';
export const NEW_LEAVE = 'NEW_LEAVE';
export const DELETE_LEAVE = 'DELETE_LEAVE';
export const APPROVE_LEAVE = 'APPROVE_LEAVE';
export const DECLINE_LEAVE = 'DECLINE_LEAVE';

export function leaves(params) {
  return asyncAction(LEAVES, ApiClient.leaves(params));
}

export function getLeave(id) {
  return asyncAction(GET_LEAVE, ApiClient.getLeave(id));
}

export function editLeave(leave) {
  return asyncAction(EDIT_LEAVE, ApiClient.editLeave(leave));
}

export function newLeave(leave) {
  return asyncAction(NEW_LEAVE, ApiClient.newLeave(leave));
}

export function deleteLeave(leave) {
  return asyncAction(DELETE_LEAVE, ApiClient.deleteLeave(leave));
}

export function approveLeave(id) {
  return asyncAction(APPROVE_LEAVE, ApiClient.approveLeave(id));
}

export function declineLeave(id) {
  return asyncAction(DECLINE_LEAVE, ApiClient.declineLeave(id));
}
