import {connect} from 'react-redux';
import ReviewsIndex from '../../views/reviews/reviews_index';
import {reviews, deleteReview} from '../../actions/reviews_actions';

function mapStateToProps(state) {
  return {
    reviews: state.reviews.reviews,
    currentPage: state.reviews.currentPage,
    totalPage: state.reviews.totalPage,
    nextSurveyDate: state.reviews.nextSurveyDate,
    token: state.reviews.token
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getReviews(params) {
      dispatch(reviews(params));
    },
    deleteReview(id) {
      dispatch(deleteReview(id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsIndex);
