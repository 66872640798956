import {connect} from 'react-redux';
import ProjectShow from '../../views/projects/project_show';
import {getProject} from '../../actions/projects_actions';

function mapStateToProps(state) {
  return {
    project: state.projects.project,
    id: state.projects.id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProject(params) {
      dispatch(getProject(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectShow);
