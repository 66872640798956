import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import ReactMde from 'react-mde';
import {Converter} from 'showdown';
import ErrorMessage from "../../components/ErrorMessage";

export default class WikiNew extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.post = {};
    this.submitted = false;
    this.converter = new Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  componentWillMount() {
    this.goBack = this.goBack.bind(this);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.props.getCategories();
  }

  goBack() {
    this.props.history.goBack();
  }

  componentWillReceiveProps(newProps) {
    if (this.submitted && newProps.post) {
      return this.goBack();
    }
    this.categories = newProps.categories;
    this.forceUpdate();
  }

  submit() {
    this.submitted = true;
    this.props.newPost(this.post);
  }

  errorMessage(errors) {
    if (errors) {
      return (
        <ErrorMessage message={errors.message}/>
      );
    }
  }

  renderCategories() {
    let that = this;
    if (this.categories) {
      return (this.categories.map((category, index) =>
        <option value={category.id} selected={category.id == that.post.category_id}>{category.title}</option>
      ));
    }
  }

  handleChange(value) {
    if (value != this.post.content) {
      this.post.content = value;
      this.forceUpdate();
    }
  }

  renderFormData() {
    return (
      <form className="form">

        <div className="form-group">
          <label>Title</label>
          <input className="form-control" onChange={e => this.post.title = e.target.value}/>
        </div>

        <div className="form-group">
          <label>Category</label>
          <select className="form-control" onChange={e => this.post.category_id = e.target.value}>
            { this.renderCategories() }
          </select>
        </div>

        <div className="form-group">
          <label>Description</label>
          <input className="form-control" onChange={e => this.post.description = e.target.value}/>
        </div>

        <div className="form-group">
          <label>Content</label>
          <ReactMde
            onChange={this.handleChange}
            value={this.post.content}
            generateMarkdownPreview={ markdown => Promise.resolve(this.converter.makeHtml(markdown)) }/>
        </div>

        <button className="btn btn-primary" type="button" onClick={this.submit}>Submit</button>

      </form>
    );
  }

  render() {
    return (
      <div className="col-sm-12">
        {this.errorMessage(this.props.errors)}

        <div className="content-title">
          <h3>New Post</h3>
          <div className="actions">
            <Link to="" onClick={this.goBack}>Back</Link>
          </div>
        </div>

        {this.renderFormData()}
      </div>
    );
  }
}
