import {connect} from 'react-redux';
import TimesheetsIndex from '../../views/timesheets/timesheets_index';
import {timesheets} from '../../actions/timesheets_actions';


function mapStateToProps(state) {
  return {
    timesheets: state.timesheets.timesheets,
    currentPage: state.timesheets.currentPage,
    totalPage: state.timesheets.totalPage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTimesheets(params) {
      dispatch(timesheets(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetsIndex);
