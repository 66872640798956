import React, {Component} from 'react';
import AuthUtil from "../../util/auth_util";
import {Link} from 'react-router-dom';
import LeaveTrackingExportDialog from './leave_tracking_export_dialog';
import ErrorMessage from "../../components/ErrorMessage";
import Pagination from "../shared/pagination";
import Icon from "../../components/Icon";

export default class LeavesIndex extends Component {
  constructor() {
    super();
    this.col_order = 'asc';
    this.col_sort_by = '';
  }

  componentWillMount() {
    this.reloadData();
    this.showExportLeaveTrackingDialog = this.showExportLeaveTrackingDialog.bind(this);
  }

  showExportLeaveTrackingDialog() {
    this.dialog.show();
  }

  reloadData() {
    this.props.getLeaves();
  }

  renderButtons(leave) {
    if (AuthUtil.isSuperadmin() || AuthUtil.isAdmin()) {
      if (leave.status === 'pending') {
        return (
          <div>
            <a onClick={this.declineLeaveBtn.bind(this, leave.leave_id)}>Decline</a>
            <a onClick={this.approveLeaveBtn.bind(this, leave.leave_id)}>Approve</a>
            <Link to={"/admin/leaves/" + leave.leave_id + "/edit"}>Edit</Link>
          </div>
        );
      }
    } else if (AuthUtil.isStaff()) {
      if (leave.status === 'pending') {
        return (
          <div>
            <Link to={"/admin/leaves/" + leave.leave_id + "/edit"}>Edit</Link>
            <a onClick={this.deleteLeaveBtn.bind(this, leave.leave_id)}>Delete</a>
          </div>
        );
      } else if (leave.status === 'approved') {
        return (
          <div>
            <a onClick={this.deleteLeaveBtn.bind(this, leave.leave_id)}>Delete</a>
          </div>
        );
      }
    }
  }

  approveLeaveBtn(id) {
    this.props.approveLeave(id);
    window.location.reload();
  }

  declineLeaveBtn(id) {
    this.props.declineLeave(id);
    window.location.reload();
  }

  deleteLeaveBtn(id) {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      this.props.deleteLeave(id);
      window.location.reload();
    }
  }

  newLeaveRequestBtn() {
    if (AuthUtil.isSuperadmin() || AuthUtil.isAdmin()) {
      return (
        <Link to={"/admin/leaves/new"}>Create new leave request</Link>
      );
    }
  }

  body() {
    if (this.props.leaves == undefined || this.props.leaves.length == 0) {
      return (
        <tr>
          <td colSpan="5">No Data</td>
        </tr>
      );
    }

    return (
      this.props.leaves.map((leave, index) =>
        <tr>
          <td width="20px">{index + 1}</td>
          <td>{leave.username}</td>
          <td>{leave.requested_at}</td>
          <td>{leave.reason}</td>
          <td width="300px">
            <div className="row">
              <div className="col">
                <p><strong>From</strong></p>
                <p>{leave.from_date}</p>
              </div>

              <div className="col">
                <p><strong>To</strong></p>
                <p>{leave.to_date}</p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <p><strong>Days</strong></p>
                <p>{leave.days}</p>
              </div>

              <div className="col">
                <p><strong>Hour</strong></p>
                <p>{leave.hours}</p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <p><strong>ID</strong></p>
                <p>{leave.leave_id}</p>
              </div>
            </div>
          </td>
          <td>
            <span className={ "badge badge-pill" + (leave.status === 'approved' ? ' badge-success' : 'badge-light') }>
              {leave.status}
            </span>
          </td>
          <td>
            {this.renderButtons(leave)}
          </td>
        </tr>
      )
    );
  }

  errorMessage(errors) {
    if (errors) {
      return <ErrorMessage message={errors.message}/>;
    }
  }

  onChangePage(page) {
    let params;

    if (this.col_sort_by) {
      params = {page: page, sort_by: this.col_sort_by, order: this.col_order};
    }
    else {
      params = {page: page};
    }
    this.props.getLeaves(params);
  }

  // col = username, requested_at, days, hours, status
  sortBy(col) {
    if (this.col_order === 'asc') {
      this.col_order = 'desc';
    }
    else {
      this.col_order = 'asc';
    }

    this.col_sort_by = col;
    this.props.getLeaves({sort_by: col, order: this.col_order});
  }

  render() {
    return (
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Leave Tracking</h5>

          <div class="card-actions">
            <a href="" class="btn btn-warning" >
                <Icon icon="plus" className="mr-1" />
                New Leave Request
            </a>

            <a href="" class="btn btn-primary" >
                <Icon icon="download" className="mr-1" />
                Export Leave
            </a>
          </div>
        </div>

        <table class="table">
        {this.errorMessage(this.props.errors)}

          <thead>
            <tr>
              <th>#</th>
              <th>
                <a onClick={this.sortBy.bind(this, 'username')}>Staff</a>
              </th>
              <th>
                <a onClick={this.sortBy.bind(this, 'requested_at')}>Requested at</a>
              </th>
              <th>Reason</th>
              <th>
                Leave Information
              </th>
              <th>
                <a onClick={this.sortBy.bind(this, 'status')}>Status</a>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.body()}
          </tbody>
        </table>

      <div className="card-footer">
        <Pagination totalPages={this.props.totalPages} onChangePage={this.onChangePage.bind(this)} />
      </div>

      <LeaveTrackingExportDialog ref={ el => this.dialog = el } projectId={this.projectId} />

    </div>
    );
  }
}
