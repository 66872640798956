export const ACTION_STATES = {
  'BEGIN': 'BEGIN',
  'FINISH': 'FINISH',
  'ERROR': 'ERROR'
};

export function asyncAction(type, promise) {
  return dispatch => {
    dispatch(actionWithState(type, ACTION_STATES.BEGIN));

    return promise
            .then((response) => {
              dispatch(actionWithState(type, ACTION_STATES.FINISH, response.data));
            }).catch((error) => {
              dispatch(actionWithState(type, ACTION_STATES.ERROR, null, error.response.data));
            });
  }
}

export function actionWithState(type, state, object=null, data=null) {
  return {
    type: type,
    state: state,
    object: object,
    data: data
  };
}
