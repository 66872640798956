import {connect} from 'react-redux';
import {logout} from '../actions/auth_actions';
import Dashboard from '../views/dashboard';

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout() {
      dispatch(logout());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
