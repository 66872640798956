import {connect} from 'react-redux';
import CategoriesIndex from '../../views/wikis/categories_index';
import {categories, deleteCategory, moveUpCategory,
  moveDownCategory, changePositionCategory} from '../../actions/wikis_actions';


function mapStateToProps(state) {
  return {
    id: state.wikis.id,
    categories: state.wikis.categories,
    currentPage: state.wikis.currentPage,
    totalPages: state.wikis.totalPages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCategories(params) {
      dispatch(categories(params));
    },
    deleteCategory(id) {
      dispatch(deleteCategory(id));
    },
    moveUp(category) {
      dispatch(moveUpCategory(category));
    },
    moveDown(category) {
      dispatch(moveDownCategory(category));
    },
    changePosition(category, position) {
      dispatch(changePositionCategory(category, position));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesIndex);
