import React, {Component} from 'react';

export default class LoadingButton extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button type={this.props.type == null ? 'button' : this.props.type}
              name="button"
              className={"btn btn-primary btn-block" + (this.props.loading ? ' btn-loading' : '')}
              onClick={this.onClick} >{this.props.title}</button>
    );
  }

}
