import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

// Containers
import LoginContainer from '../containers/LoginContainer';
import SignUpContainer from '../containers/SignUpContainer';
import DashboardContainer from '../containers/DashboardContainer';

export default class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/login" component={LoginContainer} />
        <Route exact path="/sign_up" component={SignUpContainer} />
        <Route path="/admin" component={DashboardContainer} />
        <Redirect from="*" to="/login" />
      </Switch>
    );
  }
}
