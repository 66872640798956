import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Pagination from '../shared/pagination';
import AuthUtil from '../../util/auth_util';
import ConfirmDeleteDialog from '../shared/confirm_delete_dialog';

export default class WikisStaffIndex extends Component {

  componentWillMount() {
    this.reloadData();
    this.categories = [];
  }

  componentWillReceiveProps(newProps) {
    this.categories = newProps.categories;
    this.forceUpdate();
  }

  reloadData() {
    this.props.getStaffPosts();
  }

  renderPosts(posts) {
    if (posts) {
      return posts.map((post, index) =>
        <li><Link to={'/admin/wikis/' + post.id}>{post.title}</Link></li>
      );
    }
  }

  renderCategories() {
    if (this.categories) {
      return this.categories.filter((category) => category.posts && category.posts.length > 0)
      .map((category, index) =>
        <div>
          <h5>{ category.title }</h5>
          <ol>
            { this.renderPosts(category.posts) }
          </ol>
        </div>
      );
    }
  }

  render() {

    return (
      <div className="col-sm-12">

        <div className="content-title">
          <h3>Wiki</h3>
        </div>

        { this.renderCategories() }
      </div>
    );
  }
}
