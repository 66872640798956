import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import TextFieldComponent from '../components/TextFieldComponent';
import LoadingButton from '../components/LoadingButton';
import AuthUtil from '../util/auth_util';

export default class Login extends Component {

  constructor(props) {
    super(props);

    this.username = "";
    this.password = "";
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    var target = event.target;
    this[target.name] = target.value;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.login({username: this.username, password: this.password})
  }

  componentWillMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push('/admin');
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isLoggedIn) {
      if (AuthUtil.isStaff()) {
        this.props.history.push('/admin/leaves');
      } else if (AuthUtil.isClient()) {
        // this.props.history.push('/admin');
      } else if (AuthUtil.isSuperadmin()) {
        this.props.history.push('/admin/projects');
      } else if (AuthUtil.isAdmin()) {
        this.props.history.push('/admin/projects');
      }

    }
  }

  render() {
    return (

      <div className="wrapper-login">
        <div className="card card-login">
            <div className="card-body">
                <h3 className="card-title mb-3">RTH InterSys</h3>
                <form method="post" onSubmit={this.handleSubmit}>
                    <TextFieldComponent
                      title="Username"
                      name="username"
                      error={this.props.error}
                      onChange={this.handleChange}
                      placeholder="Username"
                      />

                    <TextFieldComponent
                      title="Password"
                      name="password"
                      onChange={this.handleChange}
                      type="password"
                      placeholder="Password"
                      />

                    <div className="actions">
                        <button className="btn btn-primary" id="login">Login</button>
                        <div className="fill"></div>
                        <div className="loading">
                        { this.props.isLoading &&
                            <div className="threedots">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        }
                        </div>
                    </div>


                </form>
            </div>
        </div>
    </div>
    );
  }
}
