import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import ErrorMessage from '../../components/ErrorMessage';
import {Converter} from 'showdown';

export default class WikiShow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.post = undefined;
  }

  componentWillMount() {
    this.postId = this.props.match.params.id;
    this.reloadData();
    this.goBack = this.goBack.bind(this);
    this.converter = new Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.post) {
      this.post = newProps.post;
      this.forceUpdate();
    }
  }

  reloadData() {
    this.props.getPost(this.postId);
  }

  goBack() {
    this.props.history.goBack();
  }

  renderData() {
    return (
      <div dangerouslySetInnerHTML={{__html: this.converter.makeHtml(this.post.content)}}></div>
    );
  }

  render() {
    if (this.post == undefined) {
      return <div className="col-sm-12">
        <p>No information</p>
      </div>
    }

    return (
      <div className="col-sm-12">

        <div className="content-title">
          <h3>{this.post.title}</h3>
          <div className="actions">
            <Link to="" onClick={this.goBack}>Back</Link>
          </div>
        </div>

        <p>{this.post.category}</p>
        {this.renderData()}

      </div>
    );
  }
}
