import React, {Component} from 'react';
import {Link} from 'react-router-dom';
// import TimesheetExportDialog from './timesheet_export_dialog';

export default class ReviewShow extends Component {

  componentWillMount() {
    this.reviewId = this.props.match.params.id;
    this.reloadData();
    this.goBack = this.goBack.bind(this);
    // this.showExportTimesheetDialog = this.showExportTimesheetDialog.bind(this);
  }

  // showExportTimesheetDialog() {
  //   this.dialog.show();
  // }

  goBack() {
    this.props.history.goBack();
  }

  reloadData() {
    this.props.getReview(this.reviewId);
  }

  body() {
    if (this.props.results == undefined || this.props.results.length == 0) {
      return (
        <tr>
          <td colSpan="5">No Data</td>
        </tr>
      );
    }

    return (
      this.props.results.map((review, index) =>
        <tr>
          <td style={{maxWidth: 150 + 'px'}}>{review.question_id}</td>
          <td style={{maxWidth: 150 + 'px'}}>{review.answer_id}</td>
          <td>{review.count}</td>
        </tr>
      )
    );
  }

  comments() {
    if (this.props.comments == undefined || this.props.comments.length == 0) {
      return (
        <tr>
          <td colSpan="5">No Data</td>
        </tr>
      );
    }

    return (
      this.props.comments.map((c, index) =>
        <tr>
          <td>{c.comment}</td>
        </tr>
      )
    );
  }

  render() {
    return (
      <div className="col-sm-12">

        <div className="content-title">
          <h3>Result</h3>
          <div className="actions">
            <Link to="" onClick={this.goBack}>Back</Link>
            <a href={"../export/reviews/" + this.reviewId + "?token=" + this.props.token} target="_blank">Export result</a>
            {/*<Link to="/export_result">Export result</Link>*/}
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {this.body()}
          </tbody>
        </table>

        <table className="table">
          <thead>
            <tr>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {this.comments()}
          </tbody>
        </table>
        {/*<TimesheetExportDialog ref={ el => this.dialog = el } projectId={this.projectId} />*/}

      </div>
    );
  }
}
