import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Pagination from '../shared/pagination';
import AuthUtil from "../../util/auth_util";
import Icon from '../../components/Icon';

export default class ProjectsIndex extends Component {

  componentWillMount() {
    this.reloadData();
  }

  reloadData() {
    this.props.getProjects({page: 1});
  }

  onChangePage(page) {
    this.props.getProjects({page: page});
  }

  body() {
    if (this.props.projects == undefined || this.props.projects.length == 0) {
      return (
        <tr>
          <td colSpan="5">No Data</td>
        </tr>
      );
    }

    return (
      this.props.projects.map((project, index) =>
        <tr>
          <td>{index + 1}</td>
          <td>{project.id}</td>
          <td><Link to={"/admin/projects/" + project.id}>{project.name}</Link></td>
          <td>{project.code}</td>
          <td>
            <span className={ 'badge badge-pill ' + (project.status == 'Active' ? 'badge-success' : 'badge-light')}>
              {project.status}
            </span>
          </td>
          <td>
            <Link className="mr-2" to={"/admin/projects/" + project.id + "/edit"}>
              <Icon icon="edit" />
            </Link>

            <a><Icon icon="trash" /></a>
          </td>
        </tr>
      )
    );
  }

  render() {
    if (AuthUtil.isSuperadmin() || AuthUtil.isAdmin()) {
      return (
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Projects</h5>
          </div>

          <table className="table table-hover">
            <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Project Name</th>
              <th>Project Code</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {this.body()}
            </tbody>
          </table>

          <div className="card-footer">
            <Pagination totalPages={this.props.totalPages} onChangePage={this.onChangePage.bind(this)}/>
          </div>

        </div>
      );
    } else {
      return (
        <div className="col-sm-12">
          <Link to="leaves">
            You don't have permission to access this resource.
          </Link>
        </div>
      );
    }
  }
}
