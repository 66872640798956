import {LEAVES, NEW_LEAVE, GET_LEAVE, EDIT_LEAVE, DELETE_LEAVE, APPROVE_LEAVE} from '../actions/leaves_actions';
import {GET_STAFF_LIST} from "../actions/staffs_actions";
import {ACTION_STATES} from '../actions/core_action';
import handleActionState from './core_reducer';

export default function leaves(state = {}, action) {
  state = handleActionState(state, action);
  switch (action.type) {
  case LEAVES:
    return Object.assign({}, state, {
      leaves: state.object ? state.object.leaves : undefined,
      currentPage: state.object ? state.object.current_page : undefined,
      totalPages: state.object ? state.object.total_pages : undefined
    });
  case GET_LEAVE:
    return Object.assign({}, state, {
      id: state.object ? state.object.id : undefined,
      leave: state.object ? state.object : undefined,
      isUpdated: false,
      error: state.error
    });
  case EDIT_LEAVE:
    return Object.assign({}, state, {
      id: state.object ? state.object.id : undefined,
      leave: state.object ? state.object : undefined,
      isUpdated: !!state.object,
      error: state.error
    });
  case DELETE_LEAVE:
  case APPROVE_LEAVE:
    return Object.assign({}, state, {
      id: state.object ? state.object.id : undefined,
      leave: state.object ? state.object : undefined,
      isUpdated: !!state.object
    });
  default:
    return state;
  }
}
