import { POSTS, GET_POST, NEW_POST, EDIT_POST, DELETE_POST, GET_STAFF_POSTS } from '../actions/wikis_actions';
import { CATEGORIES, GET_CATEGORY, NEW_CATEGORY,
        EDIT_CATEGORY, DELETE_CATEGORY, MOVE_UP_CATEGORY,
        MOVE_DOWN_CATEGORY, CHANGE_POSITION_CATEGORY } from '../actions/wikis_actions';
import {ACTION_STATES} from '../actions/core_action';
import handleActionState from './core_reducer';

export default function wikis(state = {}, action) {
  state = handleActionState(state, action);
  switch (action.type) {
  case POSTS:
    return Object.assign({}, state, {
      posts: state.object ? state.object.posts : undefined,
      currentPage: state.object ? state.object.current_page : undefined,
      totalPages: state.object ? state.object.total_pages : undefined
    });
  case GET_POST:
    return Object.assign({}, state, {
      post: state.object ? state.object : undefined
    });
  case NEW_POST:
    return Object.assign({}, state, {
      post: state.object ? state.object : undefined
    });
  case EDIT_POST:
    return Object.assign({}, state, {
      post: state.object ? state.object : undefined
    });
  case DELETE_POST:
    return Object.assign({}, state, {
      id: state.object ? state.object.id : undefined
    });
  case GET_STAFF_POSTS:
    return Object.assign({}, state, {
      categories: state.object ? state.object : undefined
    });

  case CATEGORIES:
    return Object.assign({}, state, {
      categories: state.object ? state.object.categories : undefined,
      currentPage: state.object ? state.object.current_page : undefined,
      totalPages: state.object ? state.object.total_pages : undefined
    });
  case GET_CATEGORY:
    return Object.assign({}, state, {
      category: state.object ? state.object : undefined
    });
  case NEW_CATEGORY:
    return Object.assign({}, state, {
      category: state.object ? state.object : undefined
    });
  case EDIT_CATEGORY:
    return Object.assign({}, state, {
      category: state.object ? state.object : undefined
    });
  case DELETE_CATEGORY:
    return Object.assign({}, state, {
      id: state.object ? state.object.id : undefined
    });

  case MOVE_UP_CATEGORY:
  case MOVE_DOWN_CATEGORY:
  case CHANGE_POSITION_CATEGORY:
    return Object.assign({}, state, {
      id: state.object ? state.object.id : undefined
    });

  default:
      return state;
  }
}
