import React, {Component} from 'react';
import AuthUtil from "../../util/auth_util";
import {Link} from 'react-router-dom';
import Pagination from '../shared/pagination';
import Icon from '../../components/Icon';

export default class ReviewsIndex extends Component {

  componentWillMount() {
    this.reloadData();
  }

  reloadData() {
    this.props.getReviews({page: 1});
  }

  onChangePage(page) {
    this.props.getReviews({page: page});
  }

  renderButtons(review) {
    // if (review.status !== 'pending') return(null);

    // if (AuthUtil.isSuperadmin() || AuthUtil.isAdmin()) {
    //   return (
    //     <div>
    //       <Link to="" onClick={this.declineLeaveBtn.bind(this, leave.leave_id)}>Decline</Link>
    //       <Link to="" onClick={this.approveLeaveBtn.bind(this, leave.leave_id)}>Approve</Link>
    //     </div>
    //   );
    // } else if (AuthUtil.isStaff()) {
        return (
          <div>
            {/*<Link to="" onClick={this.deleteReviewBtn.bind(this, review.review_id)}>Delete</Link>*/}
            <Link to={"/admin/reviews/" + review.review_id}>View</Link>
          </div>
        );
    // }
  }


  deleteReviewBtn(id) {
    console.log('delete pressed');
    this.props.deleteReview(id);
    window.location.reload();
  }

  body() {
    if (this.props.reviews == undefined || this.props.reviews.length == 0) {
      return (
        <tr>
          <td colSpan="6">No Data</td>
        </tr>
      );
    }

    return (
      this.props.reviews.map((review, index) =>
        <tr>
          <td>{index + 1}</td>
          <td>{review.review_id}</td>
          <td>{review.username}</td>
          <td>{review.time}</td>
          <td>{review.number_of_reviews}/{review.total_number_of_reviews}</td>
          <td>
            {this.renderButtons(review)}
          </td>
        </tr>
      )
    );
  }

  render() {
    if (AuthUtil.isSuperadmin() || AuthUtil.isAdmin()) {
      return (
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Reviews</h5>
            <div className="card-actions">
              <a className="btn btn-primary" href={"../export/reviews?token=" + this.props.token} target="_blank">
                <Icon icon="download" className="mr-1"/>
                Export summary rating
              </a>
              {/*<Link to="/export_result">Export result</Link>*/}
            </div>
          </div>

          <div className="card-body">
            <p>Next Scheduled Survey Date: <b>{this.props.nextSurveyDate}</b></p>
          </div>

          <table className="table table-hover">
            <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Staff</th>
              <th>Survey date</th>
              <th>Number of reviews</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {this.body()}
            </tbody>
          </table>

          <Pagination totalPages={this.props.totalPage} onChangePage={this.onChangePage.bind(this)}/>
        </div>
      );
    } else {
      return (
        <div className="col-sm-12">
          <Link to="leaves">
            You don't have permission to access this resource.
          </Link>
        </div>
      );
    }
  }
}
