import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {render} from 'react-dom';
import store from './util/store';
import App from './components/app';

require("script-loader!jquery");
require("./assets/js/app.js");
require("style-loader!./assets/css/app.scss");
require("style-loader!../node_modules/draft-js/dist/Draft.css");
require("style-loader!../node_modules/react-mde/lib/styles/css/react-mde-all.css");

const appStore = store();

render(
  <Provider store={appStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>, document.getElementById('app'));
