import {connect} from 'react-redux';
import ReviewShow from '../../views/reviews/review_show';
import {getReview} from '../../actions/reviews_actions';

function mapStateToProps(state) {
  return {
    results: state.reviews.results,
    comments: state.reviews.comments,
    token: state.reviews.token
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getReview(params) {
      dispatch(getReview(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewShow);
