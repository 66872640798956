import {connect} from 'react-redux';
import {logout} from '../actions/auth_actions';
import Sidebar from '../views/sidebar';

function mapDispatchToProps(dispatch) {
  return {
    logout() {
      dispatch(logout());
    }
  }
}

export default connect(null, mapDispatchToProps)(Sidebar);
