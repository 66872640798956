import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {logout} from '../actions/auth_actions';
import AuthUtil from '../util/auth_util';
import Icon from '../components/Icon';

class Sidebar extends Component {

  constructor(props) {
    super(props);
  }

  isActive(section) {
    return this.props.section == section ? 'active' : ''
  }

  renderSidebar() {
    if (AuthUtil.isStaff()) {
      return this.renderStaffSidebar();
    } else if (AuthUtil.isClient()) {
      return this.renderStaffSidebar();
    } else if (AuthUtil.isSuperadmin()) {
      return this.renderSuperadminSidebar();
    } else if (AuthUtil.isAdmin()) {
      return this.renderAdminSidebar();
    }
  }

  renderSuperadminSidebar() {
    return this.renderAdminSidebar();
  }

  renderAdminSidebar() {
    return (
      <nav class="sidebar-content">
        <ul>
          <li>
            <Link to="/admin/projects" className={this.isActive('/admin/projects')}>
              <Icon icon="trello"/>
              Projects
            </Link>
          </li>

          <li>
            <Link to="/admin/timesheets" className={this.isActive('/admin/timesheets')}>
              <Icon icon="watch"/>
              Timesheets
            </Link>
          </li>

          <li>
            <Link to="/admin/customers" className={this.isActive('/admin/customers')}>
              <Icon icon="briefcase"/>
              Customers
            </Link>
          </li>

          <li>
            <Link to="/admin/staffs" className={this.isActive('/admin/staffs')}>
              <Icon icon="users"/>
              Staffs
            </Link>
          </li>

          <li>
            <Link to="/admin/reviews" className={this.isActive('/admin/reviews')}>
              <Icon icon="message-square"/>
              Reviews
            </Link>
          </li>

          <li>
            <Link to="/admin/leaves" className={this.isActive('/admin/leaves')}>
              <Icon icon="calendar"/>
              Leave Tracking
            </Link>
          </li>

          <li>
            <Link to="/admin/equipments" className={this.isActive('/admin/equipments')}>
              <Icon icon="monitor"/>
              Equipments
            </Link>
          </li>

          <li>
            <Link to="/admin/sharing_sessions" className={this.isActive('/admin/sharing_sessions')}>
              <Icon icon="compass"/>
              Sharing Sessions
            </Link>
          </li>

          <li>
            <Link to="/admin/wikis" className={this.isActive('/admin/wikis')}>
              <Icon icon="database"/>
              Wiki
            </Link>
          </li>

          <li>
            <Link to="/admin/categories" className={this.isActive('/admin/categories')}>
              <Icon icon="grid"/>
              Categories
            </Link>
          </li>

          <li>
            <Link to="/admin/staff_wikis" className={this.isActive('/admin/staff_wikis')}>
              <Icon icon="database"/>
              Staff Wikis
            </Link>
          </li>

        </ul>
      </nav>
    );
  }

  renderStaffSidebar() {
    return (
      <nav class="sidebar-content">
        <ul>
          <li>
            <Link to="/admin/leaves" className={this.isActive('/admin/leaves')}>
              <Icon icon="calendar"/>
              Leave Tracking
            </Link>
          </li>

          <li>
            <Link to="/admin/staff_wikis" className={this.isActive('/admin/staff_wikis')}>
              <Icon icon="database"/>
              Wikis
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  renderClientSidebar() {
    return (
      <nav class="sidebar-content">
        <ul>
        </ul>
      </nav>
    );
  }

  render() {
    return (
      <div class="sidebar">
        <a href="#" class="sidebar-brand">
          <img class="brand-img" src="/img/logo.svg"/>
          InterSys
        </a>
        {this.renderSidebar()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout() {
      dispatch(logout());
    }
  }
}

export default connect(null, mapDispatchToProps)(Sidebar);
