import {LOGIN, LOGOUT, SIGN_UP} from '../actions/auth_actions';
import {ACTION_STATES} from '../actions/core_action';
import handleActionState from './core_reducer';

export default function auth(state = {}, action) {
  state = handleActionState(state, action);
  switch (action.type) {
    case LOGIN:
    case SIGN_UP:
      return handleLogin(state, action);
    case LOGOUT:
      return handleLogout(state, action);
    default:
      return state;
  }
}

function handleLogin(state, action) {
  switch (action.state) {
    case ACTION_STATES.BEGIN:
      return Object.assign({}, state, {
        isLoggedIn: false
      });
    case ACTION_STATES.FINISH:
      return Object.assign({}, state, {
        isLoggedIn: true,
        user: state.object
      });
    case ACTION_STATES.ERROR:
      return Object.assign({}, state, {
        isLoggedIn: false
      });
    default:
      return state;
  }
}

function handleLogout(state, action) {
  switch (action.state) {
    case ACTION_STATES.FINISH:
      return Object.assign({}, state, {
        isLoggedIn: false,
        user: undefined
      });
    default:
      return state;
  }
}
