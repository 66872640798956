import {PROJECTS, GET_PROJECT, NEW_PROJECT, EDIT_PROJECT, DELETE_PROJECT, GET_CLIENT_CHANNEL_NAMES, GET_MAN_DAY_CREDIT_HISTORIES, TOP_UP_MAN_DAY_CREDITS} from '../actions/projects_actions';
import {ACTION_STATES} from '../actions/core_action';
import handleActionState from './core_reducer';

export default function projects(state = {}, action) {
  state = handleActionState(state, action);
  switch (action.type) {
    case PROJECTS:
      return Object.assign({}, state, {
        projects: state.object ? state.object.projects : undefined,
        currentPage: state.object ? state.object.current_page : undefined,
        totalPages: state.object ? state.object.total_pages : undefined
      });
    case GET_PROJECT:
      return Object.assign({}, state, {
        id: state.object ? state.object.id : undefined,
        project: state.object ? state.object : undefined
      });
    case GET_CLIENT_CHANNEL_NAMES:
      return Object.assign({}, state, {
        clientChannels: state.object ? state.object : undefined,
        error: state.error
      });
    case GET_MAN_DAY_CREDIT_HISTORIES:
      return Object.assign({}, state, {
        credit_histories: state.object ? state.object : undefined,
        currentPage: state.object ? state.object.current_page : undefined,
        totalPages: state.object ? state.object.total_pages : undefined,
        error: state.error,
      });
    case TOP_UP_MAN_DAY_CREDITS:
    case NEW_PROJECT:
    case EDIT_PROJECT:
    case DELETE_PROJECT:
    default:
      return state;
  }
}
