import {connect} from 'react-redux';
import WikisIndex from '../../views/wikis/wikis_index';
import {posts, deletePost} from '../../actions/wikis_actions';

function mapStateToProps(state) {
  return {
    id: state.wikis.id,
    posts: state.wikis.posts,
    currentPage: state.wikis.currentPage,
    totalPages: state.wikis.totalPages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPosts(params) {
      dispatch(posts(params));
    },
    deletePost(id) {
      dispatch(deletePost(id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WikisIndex);
