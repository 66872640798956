import {combineReducers} from 'redux';
import auth from './auth_reducer';
import projects from './projects_reducer';
import timesheets from './timesheets_reducer';
import staffs from './staffs_reducer';
import leaves from './leaves_reducer';
import reviews from './reviews_reducer';
import wikis from './wikis_reducer';

const reducers = combineReducers({
  auth,
  projects,
  timesheets,
  staffs,
  leaves,
  reviews,
  wikis
});

export default reducers;
