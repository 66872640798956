import {connect} from 'react-redux';
import WikiNew from '../../views/wikis/wiki_new';
import {newPost, categories} from '../../actions/wikis_actions';

function mapStateToProps(state) {
  return {
    categories: state.wikis.categories,
    post: state.wikis.post,
    errors: state.wikis.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newPost(post) {
      dispatch(newPost(post));
    },
    getCategories() {
      dispatch(categories());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WikiNew);
