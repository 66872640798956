import axios from 'axios';
import AuthUtil from './auth_util';

const APP_TOKEN = "7bhTU7zhLNHz7xUNzypm";

export default class ApiClient {

  // Generic methods
  static headers() {
    var ret = {
      'X-App-Token': APP_TOKEN,
      'Cache-Control': 'no-cache'
    };

    var accessToken = AuthUtil.getAccessToken();
    if (accessToken !== undefined) {
      ret = Object.assign({}, ret, { 'X-Access-Token': accessToken });
    }
    return ret;
  }

  static get(url, params) {
    return axios.get(url,
      {
        params: params,
        headers: ApiClient.headers()
      }
    ).catch((error) => {
      console.error(error);
    });
  }

  static post(url, data) {
    return axios.post(url,
      data,
      {
        headers: ApiClient.headers()
      }
    );
  }

  static put(url, data, params) {
    return axios.put(url,
      data,
      {
        params: params,
        headers: ApiClient.headers()
      }
    );
  }

  static delete(url) {
    return axios.delete(url,
      {
        headers: ApiClient.headers()
      }
    );
  }

  // Specific methods
  static login(user) {
    return ApiClient.post('/api/v1/users/login', user);
  }

  static logout(user) {
    return ApiClient.post('/api/v1/users/logout', user);
  }

  static signUp(user) {
    return ApiClient.post('/api/v1/users/sign_up', user);
  }

  // Projects
  static projects(params) {
    return ApiClient.get('/api/v1/projects', params);
  }

  static getProject(id) {
    return ApiClient.get('/api/v1/projects/' + id, {});
  }

  static editProject(project) {
    return ApiClient.post('/api/v1/projects/' + project.id, project);
  }

  // Slacks
  static getClientChannelNames() {
    return ApiClient.get('/api/v1/slacks/names');
  }

  // Timesheets
  static timesheets(params) {
    return ApiClient.get('/api/v1/timesheets', params);
  }

  // Staffs
  static staffs(params) {
    return ApiClient.get('/api/v1/staffs', params);
  }

  static getStaff(id) {
    return ApiClient.get('/api/v1/staffs/' + id, {});
  }

  static getStaffList() {
    return ApiClient.get('/api/v1/staffs/list');
  }

  static editStaff(staff) {
    return ApiClient.post('/api/v1/staffs/' + staff.id, staff);
  }

  // Leaves
  static leaves(params) {
    return ApiClient.get('/api/v1/leaves', params);
  }

  static getLeave(id) {
    return ApiClient.get('/api/v1/leaves/' + id, {});
  }

  static editLeave(leave) {
    let params = {
      from_date: leave.from_date,
      to_date: leave.to_date,
      hours: leave.hours,
      reason: leave.reason
    };
    return ApiClient.put('/api/v1/leaves/' + leave.leave_id, {}, params);
  }

  static approveLeave(id) {
    return ApiClient.put('/api/v1/leaves/' + id + '/status', {}, {status: "approved"});
  }

  static declineLeave(id) {
    return ApiClient.put('/api/v1/leaves/' + id + '/status', {}, {status: "declined"});
  }

  static deleteLeave(id) {
    return ApiClient.delete('/api/v1/leaves/' + id);
  }

  static newLeave(leave) {
    return ApiClient.post('/api/v1/leaves', leave);
  }

  // Reviews
  static reviews(params) {
    return ApiClient.get('/api/v1/reviews', params);
  }

  static getReview(id) {
    return ApiClient.get('/api/v1/reviews/' + id, {});
    // return ApiClient.get('/api/v1/reviews/1');
  }

  // Wikis
  static posts(params) {
    return ApiClient.get('/api/v1/posts', params);
  }

  static getPost(id) {
    return ApiClient.get('/api/v1/posts/' + id, {});
  }

  static createPost(post) {
    return ApiClient.post('/api/v1/posts', post);
  }

  static updatePost(post) {
    return ApiClient.put('/api/v1/posts/' + post.id, post);
  }

  static deletePost(id) {
    return ApiClient.delete('/api/v1/posts/' + id);
  }

  static getStaffPosts() {
    return ApiClient.get('/api/v1/posts/staff_posts');
  }

  // Categories
  static categories(params) {
    return ApiClient.get('/api/v1/categories', params);
  }

  static getCategory(id) {
    return ApiClient.get('/api/v1/categories/' + id);
  }

  static createCategory(category) {
    return ApiClient.post('/api/v1/categories', category);
  }

  static updateCategory(category) {
    return ApiClient.put('/api/v1/categories/' + category.id, category);
  }

  static deleteCategory(id) {
    return ApiClient.delete('/api/v1/categories/' + id);
  }

  static moveUpCategory(id) {
    return ApiClient.post('/api/v1/categories/' + id + '/move_up', {});
  }

  static moveDownCategory(id) {
    return ApiClient.post('/api/v1/categories/' + id + '/move_down', {});
  }

  static changePositionCategory(id, position) {
    return ApiClient.post('/api/v1/categories/' + id + '/change_position', { position: position });
  }

  // Man-day credit histories
  static getManDayCreditHistories(project_id, params) {
    return ApiClient.get('/api/v1/projects/' + project_id + '/credit_histories', params);
  }

  static topUpManDayCredits(project_id, params) {
    return ApiClient.post('/api/v1/projects/' + project_id + '/credits', params);
  }
}
