import {connect} from 'react-redux';
import LeavesIndex from '../../views/leaves/leaves_index';
import {leaves, approveLeave, declineLeave, deleteLeave} from '../../actions/leaves_actions';

function mapStateToProps(state) {
  return {
    leaves: state.leaves.leaves,
    currentPage: state.leaves.currentPage,
    totalPages: state.leaves.totalPages,
    errors: state.leaves.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLeaves(params) {
      dispatch(leaves(params));
    },
    approveLeave(id) {
      dispatch(approveLeave(id));
    },
    declineLeave(id) {
      dispatch(declineLeave(id));
    },
    deleteLeave(id) {
      dispatch(deleteLeave(id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeavesIndex);
