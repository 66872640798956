import {connect} from 'react-redux';
import ProjectsIndex from '../../views/projects/projects_index';
import {projects} from '../../actions/projects_actions';


function mapStateToProps(state) {
  return {
    projects: state.projects.projects,
    currentPage: state.projects.currentPage,
    totalPages: state.projects.totalPages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProjects(params) {
      dispatch(projects(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsIndex);
