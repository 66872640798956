import React, {Component} from 'react';

export default class ConfirmDeleteDialog extends Component {

  componentWillMount() {
    this.$dialog = $(this.dialog);
    this.action = this.props.action.bind(this);
  }

  show() {
    $(this.dialog).modal('show');
  }

  hide() {
    $(this.dialog).modal('hide');
  }

  componentWillUnmount() {
    this.hide()
  }

  render() {
    return (

      <div className="modal" tabindex="-1" role="dialog" ref={el => this.dialog = el}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Warning</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{ "Are you sure you want to delete this " + this.props.item +"?" }</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary" onClick={this.action}>Yes</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
