import React, {Component} from 'react';

export default class Pagination extends Component {

  constructor(props) {
    super(props);
    this.state = { page: 1 };
  }

  changePage(page) {
    return (e) => {
      e.preventDefault();
      this.setState({ page: page });
      this.props.onChangePage(page);
    }
  }

  renderNumbers() {
    var numbers = [];
    for (var i=1; i <= this.props.totalPages; i++) {
      numbers.push(
        <li className={ 'page-item' + (this.state.page == i ? ' active' : '')}>
          <a href="#" className="page-link" onClick={ this.changePage(i) }>{i}</a>
        </li>
      );
    }
    return numbers;
  }

  render() {
    return (
      <ul className="pagination">
        {this.renderNumbers()}
      </ul>
    );
  }

}
