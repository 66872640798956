import React, {Component} from 'react';
import {connect} from 'react-redux';
import TopNav from '../views/top_nav';

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps)(TopNav);
