import React, {Component} from 'react';

export default class TextFieldComponent extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.props.onChange.bind(this);
  }

  render() {
    return (
      <div className={ "form-group" + (this.props.error ? " has-error" : "") }>
        <label>{this.props.title}</label>
        <input
          type={this.props.type}
          name={this.props.name}
          className="form-control"
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          />

        <small className={"form-text text-muted" + (this.props.error ? '' : ' hidden')}>
          {this.props.error ? this.props.error.error : '' }
        </small>
      </div>
    );
  }

}
