import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Pagination from '../shared/pagination';
import '../../assets/css/staff.scss';
import AuthUtil from '../../util/auth_util';
import Icon from '../../components/Icon';

export default class StaffsIndex extends Component {

  componentWillMount() {
    this.reloadData();
  }

  reloadData() {
    this.props.getStaffs({page: 1});
  }

  onChangePage(page) {
    this.props.getStaffs({page: page});
  }

  leaveStatistics(staff) {
    return `(${staff.available_leaves}+${staff.bonus_days}) / ${staff.used} / ${staff.leaves_left}`;
  }

  body() {
    if (this.props.staffs == undefined || this.props.staffs.length == 0) {
      return (
        <tr>
          <td colSpan="5">No Data</td>
        </tr>
      );
    }

    return (
      this.props.staffs.map((staff, index) =>
        <tr>
          <td>{index + 1}</td>
          <td>
            <p><strong>{staff.full_name}</strong></p>
            <p>{staff.email}</p>
            <p>
              <span className="badge badge-pill badge-info" >
                {staff.id}
              </span>
            </p>
          </td>
          <td>{staff.role}</td>
          <td>
            <span className={ "badge badge-pill" + (staff.is_active ? ' badge-success' : 'badge-light' ) } >
              {staff.is_active ? 'Active' : 'Inactive'}
            </span>
          </td>
          <td>{this.leaveStatistics(staff)}</td>
          <td>
            <Link className="mr-2" to={"/admin/staffs/" + staff.id + "/edit"}>
              <Icon icon="edit" />
            </Link>
            { AuthUtil.isSuperadmin() || AuthUtil.isAdmin() ?
              <a className="mr-2" href={"/export/staff_timesheets.xlsx?staff_id=" + staff.id} target="_blank">
                <Icon icon="download" />
              </a>
              : null
            }
            <a><Icon icon="trash" /></a>
          </td>
        </tr>
      )
    );
  }

  render() {
    if (AuthUtil.isSuperadmin() || AuthUtil.isAdmin()) {
      return (
        <div className="card">
          <div className="card-header">
            <h5>Staffs</h5>
          </div>

          <table className="table table-hover">
            <thead>
            <tr>
              <th>#</th>
              <th>Staff</th>
              <th>Role</th>
              <th>Status</th>
              <th>Leave<br/>Total/Used/Left</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {this.body()}
            </tbody>
          </table>

          <div className="card-footer">
            <Pagination totalPages={this.props.totalPages} onChangePage={this.onChangePage.bind(this)}/>
          </div>

        </div>
      );
    } else {
      return (
        <div className="col-sm-12">
          <Link to="leaves">
            You don't have permission to access this resource.
          </Link>
        </div>
      );
    }
  }
}
