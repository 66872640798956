import {ACTION_STATES, actionWithState, asyncAction} from './core_action';
import ApiClient from '../util/api_client';

export const STAFFS = 'STAFFS';
export const GET_STAFF = 'GET_STAFF';
export const GET_STAFF_LIST = 'GET_STAFF_LIST';
export const EDIT_STAFF = 'EDIT_STAFF';
export const NEW_STAFF = 'NEW_STAFF';
export const DELETE_STAFF = 'DELETE_STAFF';

export function staffs(params) {
  return asyncAction(STAFFS, ApiClient.staffs(params));
}

export function getStaff(id) {
  return asyncAction(GET_STAFF, ApiClient.getStaff(id));
}

export function getStaffList() {
  return asyncAction(GET_STAFF_LIST, ApiClient.getStaffList());
}

export function editStaff(staff) {
  return asyncAction(EDIT_STAFF, ApiClient.editStaff(staff));
}

export function newStaff(staff) {
  return asyncAction(NEW_STAFF, ApiClient.newStaff(staff));
}

export function deleteStaff(staff) {
  return asyncAction(DELETE_STAFF, ApiClient.deleteStaff(staff));
}
