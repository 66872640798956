import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import ErrorMessage from "../../components/ErrorMessage";

export default class CategoryEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.category = undefined;
    this.submitted = false;
  }

  componentWillMount() {
    this.categoryId = this.props.match.params.id;
    this.reloadData();
    this.goBack = this.goBack.bind(this);
    this.submit = this.submit.bind(this);
  }

  reloadData() {
    this.props.getCategory(this.categoryId);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentWillReceiveProps(newProps) {
    if (this.submitted == true && this.category) {
      this.goBack();
      return;
    }

    this.category = newProps.category;
    this.forceUpdate();
  }

  submit() {
    this.submitted = true;
    this.props.editCategory(this.category);
  }

  errorMessage(errors) {
    if (errors) {
      return (
        <ErrorMessage message={errors.message}/>
      );
    }
  }

  renderFormData() {
    if (this.category == undefined) {
      return <p>No information</p>
    }

    let {title, description} = this.category;
    return (
      <form className="form">

        <div className="form-group">
          <label>Title</label>
          <input className="form-control" defaultValue={title} onChange={e => this.category.title = e.target.value}/>
        </div>

        <div className="form-group">
          <label>Description</label>
          <input className="form-control" defaultValue={description} onChange={e => this.category.description = e.target.value}/>
        </div>

        <button className="btn btn-primary" type="button" onClick={this.submit}>Submit</button>

      </form>
    );
  }

  render() {
    return (
      <div className="col-sm-12">
        {this.errorMessage(this.props.errors)}

        <div className="content-title">
          <h3>Update Category</h3>
          <div className="actions">
            <Link to="" onClick={this.goBack}>Back</Link>
          </div>
        </div>

        {this.renderFormData()}
      </div>
    );
  }
}
