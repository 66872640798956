import React, {Component} from 'react';
import Icon from "../../components/Icon";

export default class TimesheetExportDialog extends Component {

  componentWillMount() {
    this.state = { show: false };
    this.month = (new Date()).getMonth() + 1;
    this.year = (new Date()).getFullYear();
    this.exportTimesheet = this.exportTimesheet.bind(this);
    this.hide = this.hide.bind(this);
  }

  show() {
    this.setState({ show: true });
  }

  hide(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ show: false });
  }

  exportTimesheet() {
    console.log(this.month + " " + this.year + " " + this.props.projectId);
    if (this.month && this.year && this.props.projectId) {
      window.open('/export/timesheets.xlsx?project_id=' + this.props.projectId + "&month=" + this.month + "&year=" + this.year, "_blank");
      this.hide();
    }
  }

  renderYearOptions() {
    let result = [];
    let currentYear = new Date().getFullYear();
    for (let i = 2017; i <= currentYear + 3; i++) {
      console.log(i);
      result.push(
        <option value={i}>{i}</option>
      );
    }
    return result;
  }

  componentWillUnmount() {
    this.hide()
  }

  render() {
    return (

      <div class={"popup" + (this.state.show ? ' popup-show' : '')}>
        <div class="popup-overlay"></div>

        <div class="popup-wrapper">
          <div class="popup-content">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Export Timesheet</h5>

                <div>
                  <a href="" class="btn btn-close" onClick={this.hide}>
                    <Icon icon="x" />
                  </a>
                </div>
              </div>

              <div className="card-body">
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Month</label>
                      <select className="form-control" defaultValue={this.month} onChange={e => this.month = e.target.value}>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Year</label>
                      <select className="form-control" defaultValue={this.year} onChange={e => this.year = e.target.value}>
                        { this.renderYearOptions() }
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <button type="button" className="btn btn-secondary" onClick={this.hide}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={this.exportTimesheet}>Export</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
