import {REVIEWS, NEW_REVIEW, GET_REVIEW, EDIT_REVIEW, DELETE_REVIEW} from '../actions/reviews_actions';
import {ACTION_STATES} from '../actions/core_action';
import handleActionState from './core_reducer';

export default function reviews(state = {}, action) {
  state = handleActionState(state, action);
  switch (action.type) {
  case REVIEWS:
    return Object.assign({}, state, {
      reviews: state.object ? state.object.reviews : undefined,
      currentPage: state.object ? state.object.current_page : undefined,
      totalPage: state.object ? state.object.total_page : undefined,
      nextSurveyDate: state.object ? state.object.next_survey_date : undefined,
      token: state.object ? state.object.token : undefined
    });
  case GET_REVIEW:
    return Object.assign({}, state, {
      results: state.object ? state.object.results : undefined,
      comments: state.object ? state.object.comments : undefined,
      token: state.object ? state.object.token : undefined
    });
  case NEW_REVIEW:
  case EDIT_REVIEW:
    return Object.assign({}, state, {
      id: state.object ? state.object.id : undefined,
      review: state.object ? state.object : undefined,
      isUpdated: !!state.object
    });
  case DELETE_REVIEW:
    return Object.assign({}, state, {
      id: state.object ? state.object.id : undefined,
      review: state.object ? state.object : undefined,
      isUpdated: !!state.object
    });
  default:
    return state;
  }
}
