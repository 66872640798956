import {STAFFS, NEW_STAFF, GET_STAFF, EDIT_STAFF, DELETE_STAFF, GET_STAFF_LIST} from '../actions/staffs_actions';
import {ACTION_STATES} from '../actions/core_action';
import handleActionState from './core_reducer';

export default function staffs(state = {}, action) {
  state = handleActionState(state, action);
  switch (action.type) {
    case STAFFS:
      return Object.assign({}, state, {
        staffs: state.object ? state.object.staffs : undefined,
        currentPage: state.object ? state.object.current_page : undefined,
        totalPages: state.object ? state.object.total_pages : undefined
      });
    case GET_STAFF:
      return Object.assign({}, state, {
        id: state.object ? state.object.id : undefined,
        staff: state.object ? state.object : undefined,
        isUpdated: false
      });
    case GET_STAFF_LIST:
      return Object.assign({}, state, {
        staffs: state.object ? state.object.staffs : undefined,
        currentPage: state.object ? state.object.current_page : undefined,
        totalPages: state.object ? state.object.total_pages : undefined,
        error: state.error
      });
    case NEW_STAFF:
    case EDIT_STAFF:
      return Object.assign({}, state, {
        id: state.object ? state.object.id : undefined,
        staff: state.object ? state.object : undefined,
        isUpdated: state.object ? true : false,
      });
    case DELETE_STAFF:
    default:
      return state;
  }
}
