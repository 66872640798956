export default class AuthUtil {

  static getAccessToken() {
    var storage = AuthUtil.getUser()
    if (storage && storage.access_token) {
      return storage.access_token
    }
    return undefined
  }

  static getUser() {
    var storage = localStorage.getItem('rtm_ms') ? JSON.parse(localStorage.getItem('rtm_ms')) : undefined
    if (storage != undefined && storage.auth && storage.auth.user) {
      return storage.auth.user
    }
    return undefined
  }

  static isSuperadmin() {
    var user = AuthUtil.getUser()
    return user && user.role == 'superadmin'
  }

  static isAdmin() {
    var user = AuthUtil.getUser()
    return user && user.role == 'admin'
  }

  static isStaff() {
    var user = AuthUtil.getUser()
    return user && user.role == 'staff'
  }

  static isClient() {
    var user = AuthUtil.getUser()
    return user && user.role == 'client'
  }

}
