import {connect} from 'react-redux';
import StaffsIndex from '../../views/staffs/staffs_index';
import {staffs} from '../../actions/staffs_actions';


function mapStateToProps(state) {
  return {
    staffs: state.staffs.staffs,
    currentPage: state.staffs.currentPage,
    totalPages: state.staffs.totalPages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getStaffs(params) {
      dispatch(staffs(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffsIndex);
