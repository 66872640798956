import {connect} from 'react-redux';
import WikiShow from '../../views/wikis/wiki_show';
import {getPost} from '../../actions/wikis_actions';

function mapStateToProps(state) {
  return {
    post: state.wikis.post,
    errors: state.wikis.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPost(post) {
      dispatch(getPost(post));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WikiShow);
