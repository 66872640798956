import React, {Component} from 'react';
import {Route, Switch, Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import SidebarContainer from '../containers/SidebarContainer';
import TopNavContainer from '../containers/TopNavContainer';
import SignOutDialog from './shared/sign_out_dialog';

import TopNav from './top_nav';

// Projects Container
import ProjectsContainer from '../containers/projects/ProjectsContainer';
import ProjectShowContainer from '../containers/projects/ProjectShowContainer';
import ProjectEditContainer from '../containers/projects/ProjectEditContainer';

// Timesheets Container
import TimesheetsContainer from '../containers/timesheets/TimesheetsContainer';

// Staffs Container
import StaffsContainer from '../containers/staffs/StaffsContainer';
import StaffEditContainer from '../containers/staffs/StaffEditContainer';

// Leaves Container
import LeavesContainer from '../containers/leaves/LeavesContainer';
import LeaveEditContainer from '../containers/leaves/LeaveEditContainer';
import LeaveNewContainer from '../containers/leaves/LeaveNewContainer';

// Reviews Container
import ReviewsContainer from '../containers/reviews/ReviewsContainer';
import ReviewShowContainer from '../containers/reviews/ReviewShowContainer';

// Wikis Container
import WikisContainer from '../containers/wikis/WikisContainer';
import WikisStaffContainer from '../containers/wikis/WikisStaffContainer';
import WikiNewContainer from '../containers/wikis/WikiNewContainer';
import WikiEditContainer from '../containers/wikis/WikiEditContainer';
import WikiShowContainer from '../containers/wikis/WikiShowContainer';
import CategoriesContainer from '../containers/wikis/CategoriesContainer';
import CategoryNewContainer from '../containers/wikis/CategoryNewContainer';
import CategoryEditContainer from '../containers/wikis/CategoryEditContainer';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.showConfirmDialog = this.showConfirmDialog.bind(this);
  }

  componentWillMount() {
    this.handleUnauthenticated(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.handleUnauthenticated(newProps);
  }

  handleUnauthenticated(props) {
    if (props.isLoggedIn == false) {
      props.history.push('/login');
    }
  }

  showConfirmDialog() {
    this.dialog.show();
  }

  render() {
    return (
      <div className="wrapper">
        <SidebarContainer section={this.props.location.pathname ? this.props.location.pathname : '/admin/projects'}/>

        <div className="main">
          <TopNavContainer signOutConfirm={this.showConfirmDialog}/>
          <div className="content">
            <Switch>
              <Route exact path="/admin/leaves" component={LeavesContainer} />
              <Route exact path="/admin/projects" component={ProjectsContainer} />
              <Route exact path="/admin/projects/:id" component={ProjectShowContainer} />
              <Route exact path="/admin/projects/:id/edit" component={ProjectEditContainer} />
              <Route exact path="/admin/timesheets" component={TimesheetsContainer} />
              <Route exact path="/admin/staffs" component={StaffsContainer} />
              <Route exact path="/admin/staffs/:id/edit" component={StaffEditContainer} />
              <Route exact path="/admin/leaves" component={LeavesContainer} />
              <Route exact path="/admin/leaves/:id/edit" component={LeaveEditContainer} />
              <Route exact path="/admin/leaves/new" component={LeaveNewContainer} />
              <Route exact path="/admin/reviews" component={ReviewsContainer} />
              <Route exact path="/admin/reviews/:id" component={ReviewShowContainer} />
              <Route exact path="/admin/wikis" component={WikisContainer} />
              <Route exact path="/admin/staff_wikis" component={WikisStaffContainer} />
              <Route exact path="/admin/wikis/new" component={WikiNewContainer} />
              <Route exact path="/admin/wikis/:id" component={WikiShowContainer} />
              <Route exact path="/admin/wikis/:id/edit" component={WikiEditContainer} />
              <Route exact path="/admin/categories" component={CategoriesContainer} />
              <Route exact path="/admin/categories/new" component={CategoryNewContainer} />
              <Route exact path="/admin/categories/:id/edit" component={CategoryEditContainer} />
            </Switch>
          </div>
        </div>

        <SignOutDialog ref={ el => this.dialog = el } logout={this.props.logout} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default connect(mapStateToProps)(Dashboard);
