import {ACTION_STATES, actionWithState, asyncAction} from './core_action';
import ApiClient from '../util/api_client';

// This is for post's categories
export const CATEGORIES = 'CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const NEW_CATEGORY = 'NEW_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const MOVE_UP_CATEGORY = 'MOVE_UP_CATEGORY';
export const MOVE_DOWN_CATEGORY = 'MOVE_DOWN_CATEGORY';
export const CHANGE_POSITION_CATEGORY = 'CHANGE_POSITION_CATEGORY';

// This is for posts
export const POSTS = 'POSTS';
export const GET_POST = 'GET_POST';
export const EDIT_POST = 'EDIT_POST';
export const NEW_POST = 'NEW_POST';
export const DELETE_POST = 'DELETE_POST';
export const GET_STAFF_POSTS = 'GET_STAFF_POSTS';

// Actions

export function posts(params) {
  return asyncAction(POSTS, ApiClient.posts(params));
}

export function getPost(id) {
  return asyncAction(GET_POST, ApiClient.getPost(id));
}

export function editPost(post) {
  return asyncAction(EDIT_POST, ApiClient.updatePost(post));
}

export function newPost(post) {
  return asyncAction(NEW_POST, ApiClient.createPost(post));
}

export function deletePost(post) {
  return asyncAction(DELETE_POST, ApiClient.deletePost(post));
}

export function getStaffPosts() {
  return asyncAction(GET_STAFF_POSTS, ApiClient.getStaffPosts());
}


// Categories

export function categories(params) {
  return asyncAction(CATEGORIES, ApiClient.categories(params));
}

export function getCategory(id) {
  return asyncAction(GET_CATEGORY, ApiClient.getCategory(id));
}

export function editCategory(category) {
  return asyncAction(EDIT_CATEGORY, ApiClient.updateCategory(category));
}

export function newCategory(category) {
  return asyncAction(NEW_CATEGORY, ApiClient.createCategory(category));
}

export function deleteCategory(category) {
  return asyncAction(DELETE_CATEGORY, ApiClient.deleteCategory(category));
}

export function moveUpCategory(category) {
  return asyncAction(MOVE_UP_CATEGORY, ApiClient.moveUpCategory(category.id));
}

export function moveDownCategory(category) {
  return asyncAction(MOVE_DOWN_CATEGORY, ApiClient.moveDownCategory(category.id));
}

export function changePositionCategory(category, position) {
  return asyncAction(CHANGE_POSITION_CATEGORY, ApiClient.changePositionCategory(category.id, position));
}
