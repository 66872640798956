import {connect} from 'react-redux';
import LeaveNew from '../../views/leaves/leave_new';
import {newLeave} from '../../actions/leaves_actions';
import {getLeave} from '../../actions/leaves_actions';
import {getStaffList} from '../../actions/staffs_actions';

function mapStateToProps(state) {
  return {
    isUpdated: state.leaves.isUpdated,
    leave: state.leaves.leave,
    id: state.leaves.id,
    staffs: state.staffs.staffs,
    errors: state.leaves.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLeave(id) {
      dispatch(getLeave(id));
    },
    newLeave(params) {
      dispatch(newLeave(params));
    },
    getStaffList() {
      dispatch(getStaffList());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveNew);
