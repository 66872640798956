import {connect} from 'react-redux';
import ProjectEdit from '../../views/projects/project_edit';
import {editProject, getProject, getClientChannelNames, getManDayCreditHistories, topUpManDayCredits} from '../../actions/projects_actions';

function mapStateToProps(state) {
  return {
    isUpdated: state.projects.isUpdated,
    project: state.projects.project,
    id: state.projects.id,
    errors: state.projects.error,
    clientChannels: state.projects.clientChannels,
    manDayCreditHistories: state.projects.credit_histories,
    currentPage: state.projects.currentPage,
    totalPages: state.projects.totalPages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProject(id) {
      dispatch(getProject(id));
    },
    editProject(params) {
      dispatch(editProject(params));
    },
    getClientChannelNames() {
      dispatch(getClientChannelNames());
    },
    getManDayCreditHistories(project_id, params) {
      dispatch(getManDayCreditHistories(project_id, params));
    },
    topUpManDayCredits(project_id, params) {
      dispatch(topUpManDayCredits(project_id, params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEdit);
