import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from '../reducers/reducers';

export default function store() {

  const persistedState = localStorage.getItem('rtm_ms') ? JSON.parse(localStorage.getItem('rtm_ms')) : {}
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  var store = createStore(
    reducers,
    persistedState,
    composeEnhancers(
      applyMiddleware(thunkMiddleware)
    ));

  store.subscribe(() => {
    localStorage.setItem('rtm_ms', JSON.stringify(store.getState()));
  });

  return store;
}
