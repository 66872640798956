import {connect} from 'react-redux';
import CategoryNew from '../../views/wikis/category_new';
import {newCategory} from '../../actions/wikis_actions';

function mapStateToProps(state) {
  return {
    category: state.wikis.category,
    errors: state.wikis.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newCategory(category) {
      dispatch(newCategory(category));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryNew);
