import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Pagination from '../shared/pagination';
import AuthUtil from '../../util/auth_util';
import ConfirmDeleteDialog from '../shared/confirm_delete_dialog';

export default class CategoriesIndex extends Component {

  constructor() {
    super();
  }

  componentWillMount() {
    this.reloadData();
    this.selectedCategoryId = -1;
    this.deleting = false;
    this.moving = false;
    this.delete = this.delete.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.id != undefined && (this.deleting || this.moving)) {
      this.reloadData();
      this.deleting = false;
      this.moving = false;
    }
  }

  confirmDelete(id) {
    this.selectedCategoryId = id;
    this.dialog.show();
  }

  delete() {
    this.dialog.hide();
    if (this.selectedCategoryId < 0) {
      return;
    }
    this.deleting = true;
    this.props.deleteCategory(this.selectedCategoryId);
  }

  reloadData() {
    this.props.getCategories({page: 1});
  }

  // Position
  moveUp(category) {
    this.moving = true;
    this.props.moveUp(category);
  }

  moveDown(category) {
    this.moving = true;
    this.props.moveDown(category);
  }

  // Pagination
  onChangePage(page) {
    this.moving = true;
    this.props.getPosts({page: page});
  }

  body() {
    if (this.props.categories == undefined || this.props.categories.length == 0) {
      return (
        <tr>
          <td colSpan="5">No Data</td>
        </tr>
      );
    }

    return (
      this.props.categories.map((category, index) =>
        <tr>
          <td>{index + 1}</td>
          <td>{category.id}</td>
          <td>{category.title}</td>
          <td>{category.description}</td>
          <td>
            <Link to={"/admin/categories/"+ category.id + "/edit"}>Edit</Link>
            <a onClick={ () => this.confirmDelete(category.id) }>Delete</a>
            <a onClick={ () => this.moveUp(category) }>Move Up</a>
            <a onClick={ () => this.moveDown(category) }>Move Down</a>
          </td>
        </tr>
      )
    );
  }

  newCategoryButton() {
    if (AuthUtil.isSuperadmin() || AuthUtil.isAdmin()) {
      return (
        <Link to={"/admin/categories/new"}>Create new category</Link>
      );
    }
  }

  render() {

    return (
      <div className="col-sm-12">

        <div className="content-title">
          <h3>Categories</h3>
          <div className="actions">
            {this.newCategoryButton()}
          </div>
        </div>

        <p>Categories will be displayed as the below order.</p>

        <table className="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.body()}
          </tbody>
        </table>

        <Pagination totalPages={this.props.totalPages} onChangePage={this.onChangePage.bind(this)}/>

        <ConfirmDeleteDialog ref={ el => this.dialog = el } action={this.delete} item="category" />

      </div>
    );
  }
}
