import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login} from '../actions/auth_actions';
import Login from '../views/login';

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isLoading,
    isError: state.auth.isError,
    user: state.auth.user,
    error: state.auth.error,
    isLoggedIn: state.auth.isLoggedIn
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login(user) {
      dispatch(login(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
