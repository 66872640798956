import {connect} from 'react-redux';
import WikiEdit from '../../views/wikis/wiki_edit';
import {getPost, editPost, categories} from '../../actions/wikis_actions';

function mapStateToProps(state) {
  return {
    categories: state.wikis.categories,
    post: state.wikis.post,
    errors: state.wikis.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPost(id) {
      dispatch(getPost(id));
    },
    editPost(post) {
      dispatch(editPost(post));
    },
    getCategories() {
      dispatch(categories());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WikiEdit);
