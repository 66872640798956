import React, {Component} from 'react';

export default class ErrorMessage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.message) {
      return (
        <div className="alert alert-dismissible alert-warning">
          <h4>Error</h4>
          <p>{this.props.message}</p>
        </div>
      );
    }
  }
}
