import React, {Component} from 'react';
import {connect} from 'react-redux';
import {signUp} from '../actions/auth_actions';
import SignUp from '../views/sign_up';

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isLoading,
    isError: state.auth.isError,
    object: state.auth.object,
    error: state.auth.error,
    isLoggedIn: state.auth.isLoggedIn
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signUp(user) {
      dispatch(signUp(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
