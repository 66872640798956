import {connect} from 'react-redux';
import StaffEdit from '../../views/staffs/staff_edit';
import {editStaff} from '../../actions/staffs_actions';
import {getStaff} from '../../actions/staffs_actions';

function mapStateToProps(state) {
  return {
    isUpdated: state.staffs.isUpdated,
    staff: state.staffs.staff,
    id: state.staffs.id,
    errors: state.staffs.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getStaff(id) {
      dispatch(getStaff(id));
    }, editStaff(params) {
      dispatch(editStaff(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffEdit);
