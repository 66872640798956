import React, {Component} from 'react';

export default class TimesheetsIndex extends Component {

  componentWillMount() {
    this.reloadData();
  }

  reloadData() {
    this.props.getTimesheets();
  }

  body() {
    if (this.props.timesheets == undefined || this.props.timesheets.length == 0) {
      return (
        <tr>
          <td colSpan="5">No Data</td>
        </tr>
      );
    }

    return (
      this.props.timesheets.map((timesheet, index) =>
        <tr>
          <td>{index + 1}</td>
          <td>{timesheet.id}</td>
          <td>{timesheet.name}</td>
          <td>{timesheet.code}</td>
          <td><a>Edit</a><a>Delete</a></td>
        </tr>
      )
    );
  }

  render() {

    return (
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Timesheets</h5>
        </div>

        <table className="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Timesheet Name</th>
              <th>Timesheet Code</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.body()}
          </tbody>
        </table>

      </div>
    );
  }
}
