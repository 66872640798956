import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../assets/css/date-picker.scss';
import Icon from '../../components/Icon';
import moment from 'moment';

export default class AddManDayCreditsDialog extends Component {
  componentWillMount() {
    this.state = { show: false };
    this.man_day = 1;
    this.start_date = new Date();
    this.init_expiry_date = new Date(moment().add(3, 'months').format("MM-DD-YYYY"));
    this.expiry_date = this.init_expiry_date;

    this.handleStartDayChange = this.handleStartDayChange.bind(this);
    this.handleExpiryDayChange = this.handleExpiryDayChange.bind(this);
    this.addCredits = this.addCredits.bind(this);
    this.validateNumbers = this.validateNumbers.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
    this.hide = this.hide.bind(this);
  }

  show() {
    this.setState({ ...this.state, show: true });
  }

  hide(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ ...this.state, show: false });
  }

  handleStartDayChange(date) {
    this.setState({
      startDate: date
    });
    this.start_date = date.format("DD/MM/YYYY");
  }

  handleExpiryDayChange(date) {
    this.setState({
      expiryDate: date
    });
    this.expiry_date = date.format("DD/MM/YYYY");
  }

  validateNumbers(e) {
    const manDayNo = e.target.value;
    const re = /^\d*(\.\d{0,2})?$/;

    if (manDayNo === '' || re.test(manDayNo)) {
      this.man_day = manDayNo;
      this.setState({manDayNo: manDayNo});
    } else {
      this.man_day = 1;
      this.setState({manDayNo: 1});
    }
  }

  addCredits() {
    this.props.topUpManDayCredits({
      man_day: this.man_day,
      start_date: this.start_date,
      expiry_date: this.expiry_date
    });
    this.hide();
  }

  handleCheckClick() {
    this.setState({checked: !this.state.checked});

    if (!this.state.checked || !this.state.checked === undefined) {
      this.expiry_date = null;
      this.state.expiryDisabled = true;
    } else {
      this.expiry_date = this.init_expiry_date;
      this.state.expiryDisabled = false;
    }
  }

  componentWillUnmount() {
    this.hide();
  }

  render() {
    return (
      <div class={"popup" + (this.state.show ? ' popup-show' : '')}>
        <div class="popup-overlay"></div>

        <div class="popup-wrapper">
          <div class="popup-content">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Add Man-Day Credits</h5>

                <div>
                  <a href="" class="btn btn-close" onClick={this.hide}>
                    <Icon icon="x" />
                  </a>
                </div>
              </div>

              <div className="card-body">
                <div className="form-group">
                  <label>Credits</label>
                  <input type="text"
                         className="form-control"
                         required="required"
                         onChange={this.validateNumbers}
                         value={this.state.manDayNo}
                         defaultValue={this.man_day}/>
                  <p>Numbers of man-day credits</p>
                </div>

                <div className="form-group">
                  <label>Start date</label>
                  <div className="input-dp">
                    <DatePicker className="form-control"
                                selected={moment(this.start_date, 'DD-MM-YYYY')}
                                defaultValue={moment(this.start_date, 'DD-MM-YYYY')}
                                dateFormat="DD/MM/YYYY"
                                onChange={this.handleStartDayChange}/>
                  </div>
                  <p>The day when credits take effect</p>
                </div>

                <div className="form-group">
                  <label>Expiry date</label>
                  <div className="input-dp">
                    <DatePicker className="form-control"
                                selected={moment(this.expiry_date, 'DD-MM-YYYY')}
                                defaultValue={moment(this.start_date, 'DD-MM-YYYY').add(3, 'M')}
                                dateFormat="DD/MM/YYYY"
                                disabled={(this.state.expiryDisabled) ? "disabled" : ""}
                                onChange={this.handleExpiryDayChange}/>
                  </div>
                  <p>The day when all credits will be discarded</p>
                </div>

                <input
                  style={{"width": "20px"}}
                  className="checkBoxNeverExpired"
                  type="checkbox"
                  checked={this.state.checked}
                  onChange={this.handleCheckClick}
                />
                <label className="checkBoxNeverExpired">Never expired</label>

                <div className="actions">
                  <button type="button" className="btn btn-secondary" onClick={this.hide}>Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={this.addCredits}>Add</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
