import {ACTION_STATES, actionWithState, asyncAction} from './core_action';
import ApiClient from '../util/api_client';

export const REVIEWS = 'REVIEWS';
export const GET_REVIEW = 'GET_REVIEW';
export const EDIT_REVIEW = 'EDIT_REVIEW';
export const NEW_REVIEW = 'NEW_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';

export function reviews(params) {
  return asyncAction(REVIEWS, ApiClient.reviews(params));
}

export function getReview(id) {
  return asyncAction(GET_REVIEW, ApiClient.getReview(id));
}

// export function getReview(id) {
//   return asyncAction(GET_REVIEW, ApiClient.getReview(id));
// }

export function editReview(review) {
  return asyncAction(EDIT_REVIEW, ApiClient.editReview(review));
}

export function newReview(review) {
  return asyncAction(NEW_REVIEW, ApiClient.newReview(review));
}

export function deleteReview(review) {
  return asyncAction(DELETE_REVIEW, ApiClient.deleteLeave(review));
}
