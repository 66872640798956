import {ACTION_STATES, actionWithState, asyncAction} from './core_action';
import ApiClient from '../util/api_client';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGN_UP = 'SIGN_UP';

export function login(user) {
  return asyncAction(LOGIN, ApiClient.login(user));
}

export function signUp(user) {
  return asyncAction(SIGN_UP, ApiClient.signUp(user));
}

export function logout() {
  return actionWithState(LOGOUT, ACTION_STATES.FINISH, null, null);
}
