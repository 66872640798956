import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ErrorMessage from "../../components/ErrorMessage";
import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../assets/css/date-picker.scss';

export default class LeaveEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFromDayChange = this.handleFromDayChange.bind(this);
    this.handleToDayChange = this.handleToDayChange.bind(this);
  }

  componentWillMount() {
    this.leaveId = this.props.match.params.id;
    this.reloadData();
    this.goBack = this.goBack.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
  }

  handleFromDayChange(date) {
    this.setState({
      startDate: date
    });
    this.leave.from_date = date.format("DD/MM/YYYY");
  }

  handleToDayChange(date) {
    this.setState({
      endDate: date
    });
    this.leave.to_date = date.format("DD/MM/YYYY");
  }

  goBack() {
    this.props.history.goBack();
  }

  reloadData() {
    this.props.getLeave(this.leaveId);
  }

  componentWillReceiveProps(newProps) {
    this.leave = newProps.leave;
    if (newProps.isUpdated == true) {
      this.goBack();
    }

    if (this.leave) {
      this.state = {
        startDate: moment(this.leave.from_date),
        endDate: moment(this.leave.to_date)
      };
    }
  }

  submitUpdate() {
    this.props.editLeave(this.leave);
  }

  errorMessage(errors) {
    if (errors) {
      return (
        <ErrorMessage message={errors.message}/>
      );
    }
  }

  renderInformation() {
    if (this.leave) {
      return (
        <form className="form">

          <div className="form-group">
            <label>From date:</label>
            <DatePicker className="form-control"
                        selected={this.state.startDate}
                        dateFormat="DD/MM/YYYY"
                        onChange={this.handleFromDayChange}/>
          </div>

          <div className="form-group">
            <label>To date:</label>
            <DatePicker className="form-control"
                        selected={this.state.endDate}
                        dateFormat="DD/MM/YYYY"
                        onChange={this.handleToDayChange}/>
          </div>

          <div className="form-group">
            <label>Reason</label>
            <input className="form-control" defaultValue={this.leave.reason} onChange={e => this.leave.reason = e.target.value}/>
          </div>

          <div className="form-group">
            <label>Hours</label>
            <input className="form-control" defaultValue={this.leave.hours} onChange={e => this.leave.hours = e.target.value}/>
          </div>

          <button className="btn btn-primary" onClick={this.submitUpdate}>Submit</button>

        </form>
      );
    }
    return (<p>No leave information</p>);
  }

  render() {
    return (
      <div className="col-sm-12">
        {this.errorMessage(this.props.errors)}

        <div className="content-title">
          <h3>Edit Leave</h3>
          <div className="actions">
            <Link to="" onClick={this.goBack}>Back</Link>
          </div>
        </div>

        {this.renderInformation()}
      </div>
    );
  }
}
