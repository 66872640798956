import React, {Component} from 'react';

export default class LeaveTrackingExportDialog extends Component {

  componentWillMount() {
    this.month = (new Date()).getMonth() + 1;
    this.year = (new Date()).getFullYear();
    this.exportLeaveTracking = this.exportLeaveTracking.bind(this);
  }

  show() {
    $(this.dialog).modal('show');
  }

  hide() {
    $(this.dialog).modal('hide');
  }

  exportLeaveTracking() {
    console.log("Export Leave tracking " + this.month + " " + this.year + " ");
    if (this.month && this.year) {
      window.open('/export/leave_tracking.xlsx?&month=' + this.month + "&year=" + this.year, "_blank");
    }
  }

  renderYears() {
    let currentYear=new Date().getFullYear();
    let years=Array.from(Array(currentYear - 2017), (_, i) => 2017+i+1);

    return (
      years.map(year =>
        <option value={year}>{year}</option>
      )
    );
  }

  componentWillUnmount() {
    this.hide()
  }

  render() {
    return (

      <div className="modal" tabindex="-1" role="dialog" ref={el => this.dialog = el}>
        <div className="modal-dialog" role="document">
          <form className="form">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Export Leave Tracking</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Month</label>
                      <select className="form-control" defaultValue={this.month} onChange={e => this.month = e.target.value}>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Year</label>
                      <select className="form-control" defaultValue={this.year} onChange={e => this.year = e.target.value}>
                        {this.renderYears()}
                      </select>
                    </div>
                  </div>
                </div>

              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={this.exportLeaveTracking}>Export</button>
              </div>
            </div>
          </form>

        </div>
      </div>
    );
  }
}
