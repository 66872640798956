import React, {Component} from 'react';
import Icon from "../../components/Icon";
import {Link} from 'react-router-dom';
import TimesheetExportDialog from './timesheet_export_dialog';

export default class ProjectShow extends Component {

  componentWillMount() {
    this.projectId = this.props.match.params.id;
    this.reloadData();
    this.goBack = this.goBack.bind(this);
    this.showExportTimesheetDialog = this.showExportTimesheetDialog.bind(this);
  }

  showExportTimesheetDialog() {
    this.dialog.show();
  }

  goBack() {
    this.props.history.goBack();
  }

  reloadData() {
    this.props.getProject(this.projectId);
  }

  renderInformation() {
    if (this.props.project) {
      return (
        <form className="form">

          <div className="form-group">
            <label>Project ID</label>
            <input className="form-control" readOnly value={this.props.project.id} />
          </div>

          <div className="form-group">
            <label>Project Code</label>
            <input className="form-control" readOnly value={this.props.project.code}/>
          </div>

          <div className="form-group">
            <label>Project Name</label>
            <input className="form-control" readOnly value={this.props.project.name}/>
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea className="form-control" readOnly>{this.props.project.description}</textarea>
          </div>

        </form>
      );
    }
    return (<p>No project information</p>);
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">
            <Link to="" className="btn btn-light" onClick={this.goBack}><Icon icon="arrow-left" /></Link>
            Project
          </h5>
          <div className="card-actions">
            <a className="btn btn-primary" onClick={this.showExportTimesheetDialog}>
              <Icon icon="download" className="mr-1"/>
              Export Timesheet
            </a>
          </div>
        </div>

        <div className="card-body">
          {this.renderInformation()}
        </div>

        <TimesheetExportDialog ref={ el => this.dialog = el } projectId={this.projectId} />

      </div>
    );
  }
}
